import firebase from "@/utils/firebaseInit"
import { dbCollections } from "@/utils/firebaseCollection"
const db = firebase.firestore();

export default {
    namespaced: true,
    state: {
        booking: [],
        pendingBooking: [],
        rejectedBooking:[]
    },
    getters: {
        getBooking: state => state.booking,
        getPendingBooking: state => state.pendingBooking,
        getRejectedBooking: state => state.rejectedBooking
    },
    mutations: {
        setBooking(state, payload) {
            state.booking = payload;
        },
        spliceBooking(state, payload) {
            
            state.booking.splice(payload,1)
        },
        setPendingBookigData(state , payload) {
            state.pendingBooking = payload;
        },
        setRejectedBookigData(state , payload) {
            state.rejectedBooking = payload
        }
    },
    actions: {
        getBookingData(state) {
            return new Promise((resolve, reject) => {
                try {
                    let review = [];
                    db.collection(dbCollections.BOOKING).get().then(snapshot => {
                        review = [];
                        if (snapshot.empty) {
                            resolve("No Data Found.");
                        }
                        else {
                            snapshot.forEach(doc => {
                                if (doc.data())
                                    review.push(doc.data());
                            });
                            state.commit("setBooking", review);

                            if (review.length == 0) {
                                resolve("No Data Found.");
                            }
                            else {
                                resolve("Records Found.");
                            };
                        }
                    },
                        (error) => {
                            console.error("error", error);
                        });
                }
                catch (error) {
                    console.error("error", error);
                    reject(error);
                };
            });
        },
        getPendingBookingData(state) {
            return new Promise((resolve, reject) => {
                try {
                    let pendingBookings = [];
                    db.collection(dbCollections.BOOKING)
                        .where("status", "==" , 0)
                        .orderBy("createdAt", "desc")
                        .get()
                        .then(snapshot => {
                            pendingBookings = [];
                            if (snapshot.empty) {
                                resolve("No Data Found.");
                            }
                            else {
                                snapshot.forEach(doc => {
                                    if (doc.data())
                                        pendingBookings.push(doc.data());
                                });
                                state.commit("setPendingBookigData", pendingBookings);

                                if (pendingBookings.length == 0) {
                                    resolve("No Data Found.");
                                }
                                else {
                                    resolve("Records Found.");
                                };
                            }
                        })
                        .catch((error) => {
                            reject(error);
                        })
                }
                catch (error) {
                    console.error("error", error);
                    reject(error);
                };
            });
        },
        getRejectedBookingData(state) {
            return new Promise((resolve, reject) => {
                try {
                    let rejectedBookings = [];
                    db.collection(dbCollections.BOOKING)
                        .where("status", "==" , 2)
                        .orderBy("cancellationDate", "desc")
                        .get()
                        .then(snapshot => {
                            rejectedBookings = [];
                            if (snapshot.empty) {
                                resolve("No Data Found.");
                            }
                            else {
                                snapshot.forEach(doc => {
                                    if (doc.data())
                                        rejectedBookings.push(doc.data());
                                });
                                state.commit("setRejectedBookigData", rejectedBookings);

                                if (rejectedBookings.length == 0) {
                                    resolve("No Data Found.");
                                }
                                else {
                                    resolve("Records Found.");
                                };
                            }
                        })
                        .catch((error) => {
                            reject(error);
                        })
                }
                catch (error) {
                    console.error("error", error);
                    reject(error);
                };
            });
        }
    },
}
