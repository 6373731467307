import * as mutations from './sessionMutation'
import * as actions from './sessionAction'
export default{
    namespaced: true,
    state:{
        session :[],
    },
    getters: {
        getSessions: state => state.session,
    },
    mutations,
    actions,
}