import * as mutations from './faqMutation.js'
import * as actions from './faqAction.js'
export default{
    namespaced: true,
    state:{
        faq:[],
        contactus:[]
    },
    getters: {
        getFaq: state => state.faq,
        getContactUs: state => state.contactus
    },
    mutations,
    actions,
}