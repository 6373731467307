import firebase from "@/utils/firebaseInit"
import { dbCollections } from "@/utils/firebaseCollection"
const db = firebase.firestore();

export default  {
    namespaced: true,
    state:{
       requestCategory : [],
    },
    getters:{
        getRequestUserCategory: state => state.requestCategory,
    },
    mutations:{
        setRequestCategoryArr(state,payload){
            state.requestCategory = payload;
        },
    },
    actions:{
        getRequestedCategory(state){
            
            return new Promise((resolve,reject)=>{
                try{
                    var Category = [];
                    db.collection(dbCollections.SETTINGS)
                    .doc('setting')
                    .collection(dbCollections.REQUESTFORCATEGORY)
                    .orderBy('createdAt','desc')
                    .onSnapshot(snapshot=>{
                        Category = [];
                        if(snapshot.empty){
                            resolve("No Data Found.");
                        }
                        else
                        {
                            snapshot.forEach(doc=>{
                                if(doc.data().status == 0){
                                    Category.push({id:doc.id,...doc.data()});
                                }
                            });
                            state.commit("setRequestCategoryArr",Category);
            
                            if(Category.length == 0)
                            {
                            resolve("No Data Found.");
                            }
                            else
                            {
                            resolve("Records Found.");
                            };
                        }
                    }); 
                }
                catch(error){
                    reject(error);
                }
            })
        },
    }
}