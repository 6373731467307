import firebase from "@/utils/firebaseInit"
import { dbCollections } from "@/utils/firebaseCollection"
const db = firebase.firestore();

export const getSessionsData =({commit})=>{
    return new Promise((resolve,reject)=>{
        try{
            let sessions = []
            db.collection(dbCollections.SESSIONS)
                .onSnapshot(async(snapshot)=>{
                    sessions = []
                    if(snapshot.empty){
                        resolve("No Data Found")
                    }else{
                        snapshot.forEach(doc=>{
                            sessions.push({id:doc.id,...doc.data()});
                        });
                        commit('setSessionsArr',sessions);
                        if(sessions.length == 0){
                            resolve("No Data Found.");
                        }else{
                            resolve("Records Found.");
                        }
                    }
                },
                (error)=>{
                    console.error("error",error);
                });
        }catch(error){
            console.error("error",error);
            reject(error);
        }
    })
};