import * as mutations from './revanueMutation'
import * as actions from './revanueAction'
export default{
    namespaced: true,
    state:{
        revanueCalculation:{},
        amountTransectionDate:[],
        revanueGraph:{},
        planGraph:{},
        activeUserGraph:{},
        stasticGraph: {},
        learnerHistory: [],
        tutorHistory: [],
        allTransectionHistory:[]
    },
    getters: {
        getRevanueCalc: state => state.revanueCalculation,
        getAmountTransectionDate: state=>state.amountTransectionDate,
        getRevanueStartGraph: state => state.revanueGraph,
        getPlanStartGraph: state => state.planGraph,
        getStasticGraph: state => state.stasticGraph,
        getLearnerHistory: state => state.learnerHistory,
        getTutorHistory: state => state.tutorHistory,
        getallTransectionHistory: state => state.allTransectionHistory,
        getActiveUserStartGraph: state => state.activeUserGraph
    },
    mutations,
    actions,
}