import firebase from "@/utils/firebaseInit"
import { dbCollections } from "@/utils/firebaseCollection"
const db = firebase.firestore();

export default {
    namespaced: true,
    state: {
        currentOnline: []
    },
    getters: {
        getOnlineCurrent: state => state.currentOnline
    },
    mutations: {
        setOnlineCurrentArr(state, payload) {
            state.currentOnline = payload;
        },
    },
    actions: {
        getOnlineCurrentData(state) {
            return new Promise((resolve, reject) => {
                try {
                    let currentOnline = [];
                    db.collection(dbCollections.SETTINGS).doc("setting").get().then((res)=>{
                        db.collection(dbCollections.BOOKING)
                        .where("startTime", ">=", new Date(new Date().getTime() - (res.data().maxBookingTime * 60 * 1000)))
                        .where("startTime", "<=", new Date())
                        .where("status", "in", [1,5])
                        .get().then(snapshot => {
                            currentOnline = [];
                            if (snapshot.empty) {
                                resolve("No Data Found.");
                            }
                            else {
                                snapshot.forEach(doc => {
                                    if (doc.data()){
                                        currentOnline.push(doc.data());
                                    }
                                });
                                state.commit("setOnlineCurrentArr", currentOnline);
    
                                if (currentOnline.length == 0) {
                                    resolve("No Data Found.");
                                }
                                else {
                                    resolve("Records Found.");
                                };
                            }    
                        },
                            (error) => {
                                console.error("error", error);
                            });
                    })
                }
                catch (error) {
                    console.error("error", error);
                    reject(error);
                };
            });
        }
    },
}
