import axios from "axios";
const errorEmail = (emailObj , cb)=>{
    if(process.env.VUE_APP_ERROR_MAIL === 'ON'){
        axios.post(process.env.VUE_APP_COMMON_API_URL+"/sendErrorMail", {...emailObj, environment: process.env.VUE_APP_ENV} ).then((res)=>{
            cb({data : res})
        }).catch((err)=>{
            console.error({error : err})
        })
    }else{
        cb({data : "not required"})
    }
}

export {errorEmail}