import firebase from "@/utils/firebaseInit"
import { dbCollections } from "@/utils/firebaseCollection"
const db = firebase.firestore();

export default{
    namespaced: true,
    state:{
     notifications : []
    },
    getters:{
        getNotifications: state => state. notifications,
          
      },
      mutations:{
        setNotificationsArr(state,payload){
              state.notifications = payload;
          },
      },
      actions:{
        getNotificationsData(state){
              return new Promise((resolve,reject)=>{
                  try{
                      var notifications = [];
                      db.collection(dbCollections.NOTIFICATIONMANAGEMENT).orderBy("createdAt", "desc")
                      .onSnapshot(snapshot=>{
                      notifications = [];
                          if(snapshot.empty){
                              resolve("No Data Found.");
                          }
                          else
                          {
                            snapshot.forEach(doc=>{
                                if(!doc.data().isDeleted){
                                    notifications.push(doc.data());
                                }
                              });
                              state.commit("setNotificationsArr", notifications);
              
                              if( notifications.length == 0)
                              {
                              resolve("No Data Found.");
                              }
                              else
                              {
                              resolve("Records Found.");
                              };
                          }
                      }); 
                  }
                  catch(error){
                      reject('error',error);
                  }
              })
        }
        
      }
}
