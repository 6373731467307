import firebase from "@/utils/firebaseInit"
import { dbCollections } from "@/utils/firebaseCollection"
import{settingsCollectionDocs}from "@/utils/firebaseCollection"
const db = firebase.firestore();

export const getSchoolData =({commit})=>{
    return new Promise((resolve,reject)=>{
        try{
            let schools = []
            db.collection(dbCollections.SETTINGS).doc("setting")
                .collection(dbCollections.SCHOOL)
                .orderBy('createdAt','desc')
                .onSnapshot((snapshot)=>{
                    schools = []
                    if(snapshot.empty){
                        resolve("No Data Found")
                    }else{
                        snapshot.forEach(doc=>{
                            schools.push({id:doc.id,...doc.data()});
                        });
                        commit('setSchoolsArr',schools);
                        if(schools.length == 0){
                            resolve("No Data Found.");
                        }else{
                            resolve("Records Found.");
                        }
                    }
                },
                (error)=>{
                    console.error("error",error);
                });
        }catch(error){
            console.error("error",error);
            reject(error);
        }
    })
};

export const getSyllabusData =({commit})=>{
    return new Promise((resolve,reject)=>{
        try{
            let syllabus = []
            db.collection(dbCollections.SETTINGS).doc("setting")
                .collection(dbCollections.SYLLABUS)
                .orderBy('createdAt','desc')
                .onSnapshot((snapshot)=>{
                    syllabus = []
                    if(snapshot.empty){
                        resolve("No Data Found")
                    }else{
                        snapshot.forEach(doc=>{
                            syllabus.push({id:doc.id,...doc.data()});
                        });
                        commit('setSyllabusArr',syllabus);
                        if(syllabus.length == 0){
                           resolve("No Data Found.");
                        }else{
                           resolve("Records Found.");
                        }
                    }
                },
                (error)=>{
                    console.error("error",error);
                });
        }catch(error){
            console.error("error",error);
            reject(error);
        }
    })
};

export const createSchool = ({state},schoolObj)=>{
    return new Promise((resolve,reject)=>{
        try{
            db.collection(dbCollections.SETTINGS).doc("setting")
                .collection(dbCollections.SCHOOL).add(schoolObj)
                .then((res)=>{
                    db.collection(dbCollections.SETTINGS).doc("setting")
                      .collection(dbCollections.SCHOOL).doc(res.id).update({
                          id: res.id
                      }).then(()=>{
                          resolve("School Added !");
                      }).catch((err)=>{
                          reject(err)
                      })
                })
                .catch((err)=>{
                    reject(err)
                })
        }catch(error){
            reject(error);
        }
    })
};

export const createSyllabus = ({state},syllabusObj)=>{
    return new Promise((resolve,reject)=>{
        try{
            db.collection(dbCollections.SETTINGS).doc("setting")
                .collection(dbCollections.SYLLABUS).add(syllabusObj)
                .then((res)=>{
                    db.collection(dbCollections.SETTINGS).doc("setting")
                      .collection(dbCollections.SYLLABUS).doc(res.id).update({
                          id: res.id
                      }).then(()=>{
                          resolve("School Added !");
                      }).catch((err)=>{
                          reject(err)
                      })
                })
                .catch((err)=>{
                    reject(err)
                })
        }catch(error){
            reject(error);
        }
    })
};


export const getStatusData =({commit})=>{
    return new Promise((resolve,reject)=>{
        try{
            let userStatus = null;
            db.collection(dbCollections.SETTINGS)
            .doc("setting")
            .collection(settingsCollectionDocs.STATUS)
            .onSnapshot((snapshot)=>{
                userStatus = []
                if(snapshot.empty){
                    resolve("No Data Found")
                }else{
                    snapshot.forEach(doc=>{
                        if(doc.id == 'userStatus'){
                            userStatus= doc.data();
                        }
                    });
                    commit('setStatusArr',userStatus);
                    if(Object.keys(userStatus).length == 0){
                      resolve("No Data Found.");
                    }else{
                      resolve("Records Found.");
                    }
                }
            },
            (error)=>{
                console.error("error",error);
            });
        }catch(error){
            console.error("error",error);
            reject(error);
        }
    })
};
export const getTutorTypes =({commit},datas)=>{
    return new Promise((resolve,reject)=>{
        try{
            let tutorType = [];
            if(datas !== undefined && datas !== null && Object.keys(datas).length > 0){
              if(datas.tutorTypesBookingSeetings && datas.tutorTypesBookingSeetings !== null){
                let tutorTempType = datas.tutorTypesBookingSeetings ? datas.tutorTypesBookingSeetings : null 
                for(let i = 0; i < tutorTempType.length; i++){
                    let obj = {
                        id : i,
                        name : tutorTempType[i].tutorType,  
                        variant : '',
                        textColor: tutorTempType[i].textColor ?  tutorTempType[i].textColor : '#FFFFFF',
                        backgroundColor: tutorTempType[i].backgroundColor ? tutorTempType[i].backgroundColor : '#000000'
                    }
                    tutorType.push(obj)
                } 
                resolve("Records Found.");
              }else{
                resolve("No Data Found."); 
              }
              commit('getTutorTypeArray',tutorType);
            }else{
             resolve("No Data Found") 
            }
        }catch(error){
            console.error("error",error);
            reject(error);
        }
    })
};
export const getsettingArrayFeild =({commit})=>{
    return new Promise((resolve,reject)=>{
        try{
            let tutorType = {}
            db.collection(dbCollections.SETTINGS)
            .doc("setting")
            .onSnapshot(snapshot=>{
                    tutorType = {};
                    if(snapshot.empty){
                        getTutorTypes({commit},tutorType)
                        resolve("No Data Found")                        
                    }else{
                        tutorType = snapshot.data();
                        getTutorTypes({commit},tutorType)
                        commit('getsettingArray',tutorType);
                        resolve("Records Found.")
                    }
                },
                (error)=>{
                    console.error("error",error);
                });
        }catch(error){
            console.error("error",error);
            reject(error);
        }
    })
};
export const getTeachingLanguages =({commit})=>{
    return new Promise((resolve,reject)=>{
        try{
            let languageArr = []
            db.collection(dbCollections.SETTINGS)
            .doc("setting")
            .collection(settingsCollectionDocs.TEACHINGLANGUAGES)
            .onSnapshot((snapshot)=>{
                    languageArr = [];
                    if(snapshot.empty){
                        resolve("No Data Found")
                    }else{
                        snapshot.forEach(doc=>{
                            languageArr.push(doc.data());
                        });
                        commit('getTeachingLanguageArray',languageArr);
                        if(languageArr.length == 0){
                           resolve("No Data Found.");
                        }else{
                           resolve("Records Found.");
                        }
                    }
                },
                (error)=>{
                    console.error("error",error);
                });
        }catch(error){
            console.error("error",error);
            reject(error);
        }
    })
};
export const getAllStatusData =({commit})=>{
    
    return new Promise((resolve,reject)=>{
        try{
            let allStatus = null;
            db.collection(dbCollections.SETTINGS)
            .doc("status")
            .onSnapshot((doc)=>{
                allStatus = []
                if(doc.empty){
                    resolve("No Data Found")
                }else{
                    allStatus= doc.data();
                    commit('setAllStatusArr',allStatus);
                    if(Object.keys(allStatus).length == 0){
                      resolve("No Data Found.");
                    }else{
                      resolve("Records Found.");
                    }
                }
            },
            (error)=>{
                console.error("error",error);
            });
        }catch(error){
            console.error("error",error);
            reject(error);
        }
    })
};
