import * as mutations from './categoryMutation'
import * as actions from './categoryAction'
export default{
    namespaced: true,
    state:{
        category:[],
        cateGoryDisplay:[],
    },
    getters: {
        getCategories: state => state.category,
        getCategoriesDisplay: state => state.cateGoryDisplay
    },
    mutations,
    actions,
}