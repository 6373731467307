import firebase from "@/utils/firebaseInit"
import { dbCollections } from "@/utils/firebaseCollection"
const db = firebase.firestore();
export const getCategoriesData =({commit})=>{
    
    return new Promise((resolve,reject)=>{
        try{
            let categories = []
            db.collection(dbCollections.SETTINGS).doc("setting")
                .collection(dbCollections.CATEGORY)
                .orderBy('createdAt','desc')
                .onSnapshot((snapshot)=>{
                    categories = []
                    if(snapshot.empty){
                        resolve("No Data Found")
                    }else{
                        snapshot.forEach(doc=>{
                            categories.push({id:doc.id,...doc.data()});
                        });
                        var len = categories;
                        var arr = []
                        let count = 0
                        let countFun = (row) => {
                        let noofSession = 0
                        if (count >= len.length) {
                            commit('setCategoriesDisplayArr',arr)
                            commit('setCategoriesArr',categories);
                            if(categories.length == 0){
                                resolve("No Data Found.");
                            }else{
                                resolve("Records Found.");
                            }
                            return arr;
                        }
                        else{
                            db.collection(dbCollections.SESSIONS).where("category","==",row.id).where("status","!=",3).get().then((session)=>{
                            noofSession = session.size
                            var obj = {
                                categoryname : row.categoryName,
                                noOfSession : noofSession,
                                categoryid :row.id,
                                action : "action",
                                }
                            arr.push(obj);
                            count+=1
                            countFun(len[count]);
                            }).catch((error) => {
                                console.error("error",error);
                                count+=1
                                countFun(len[count]);
                            })
                        }
                        }
                        countFun(len[count]);
                    }
                },
                (error)=>{
                    console.error("error",error);
                });
        }catch(error){
            console.error("error",error);
            reject(error);
        }
    })
};

export const createCategory = ({state},categoryObj)=>{
    return new Promise((resolve,reject)=>{
        try{
            db.collection(dbCollections.SETTINGS).doc("setting")
                .collection(dbCollections.CATEGORY).add(categoryObj)
                .then((res)=>{
                    db.collection(dbCollections.SETTINGS).doc("setting").collection(dbCollections.CATEGORY).doc(res.id).update({ id: res.id }).then(() => {
                        resolve("Category Added !");
                    }).catch((error) => {
                        reject(error);
                    })
                });
        }catch(error){
            console.error("error",error);
            reject(error);
        }
    })
};