import firebase from "@/utils/firebaseInit"
import { dbCollections } from "@/utils/firebaseCollection"
const db = firebase.firestore();

export default {
  namespaced: true,
  state: {
    banners:[]
  },
  getters: {
    getBanners: state => state.banners
  },
  mutations: {
    setBannersArr(state,payload){
      state.banners = payload;
    },
  },
  actions: {
    getBannersData(state){
      return new Promise((resolve,reject)=>{
        try{
          var banners = [];
          db.collection(dbCollections.SETTINGS)
            .doc('setting')
            .collection(dbCollections.BANNERMANAGEMENT)
            .orderBy('createdAt','asc')
            .onSnapshot(snapshot=>{
              banners = [];
              if(snapshot.empty){
                resolve("No Data Found.");
              }
              else
              {
                snapshot.forEach(doc=>{
                  if(doc.data().status != 2){
                    banners.push({id:doc.id,...doc.data()});
                  }
                });
                state.commit("setBannersArr",banners);

                if(banners.length == 0)
                {
                  resolve("No Data Found.");
                }
                else
                {
                  resolve("Records Found.");
                };
              }
            },
            (error)=>{
             
              reject(error);
            });
        }
        catch(error){
         
          reject(error);
        };
      });
    },
  },
}
