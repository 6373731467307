import firebase from "@/utils/firebaseInit"
import { dbCollections } from "@/utils/firebaseCollection"
const db = firebase.firestore();

export const getTacData = ({commit})=>{
    return new Promise((resolve,reject)=>{
        try {
            let tacData = []
            db.collection(dbCollections.SETTINGS).doc("setting").collection(dbCollections.TESTTAC).onSnapshot((snapshot)=>{
                tacData = []
                if(snapshot.empty){
                    resolve("No data found>>>>>>>>>>>>>>>>>>>>>>>>>>")
                }else{
                    snapshot.forEach((doc)=>{
                        tacData.push({id:doc.id,...doc.data()})
                    })
                    commit('setTacArr',tacData);
                    if(tacData.length == 0){
                        resolve("No Data Found>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>.");
                    }else{
                        resolve("Records Found>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>.");
                    }
                }
            })
        } catch (error) {
            reject(error)
            console.error("error",error)
        }
    })
}