import * as mutations from './tacMutation'
import * as actions from './tacAction'
export default{
    namespaced: true,
    state:{
        tac:[],
    },
    getters: {
        getTAC: state => state.tac,
    },
    mutations,
    actions,
}