let data = [];
data = [
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () => import(/* webpackChunkName: "[request]" */'@/views/Dashboard/dashboardList.vue'),
    meta: {
      pageTitle: 'Dashboard',
      breadcrumb: [
        {
          text: 'Dashboard',
          active:true
        },
      ],
    },
  },

  {
    path: '/user-management/users',
    name: 'user-mgmt',
    component: () => import(/* webpackChunkName: "[request]" */'@/views/Users/User/userList.vue'),
    meta: {
      pageTitle: 'User',
      breadcrumb: [
        {
          text: 'User Management',
        },
        {
          text: 'User',
          active:true
        },
      ],
    },
  },
  {
    path: '/sessions-management/request-sessions',
    name: 'req-session-mgmt',
    component: () => import(/* webpackChunkName: "[request]" */'@/views/SessionsManagement/AllSession(s)/requestSession.vue'),
    meta: {
      pageTitle: 'Request for Session',
      breadcrumb: [
        {
          text: 'Sessions Management',
        },
        {
          text: 'Request for Session',
          active:true
        },
      ],
    },
  },
  {
    path: '/sessions-management/request-sessions/:id',
    name: 'req-session-detail',
    component: () => import(/* webpackChunkName: "[request]" */'@/views/SessionsManagement/AllSession(s)/requestSessionDetail.vue'),
    meta: {
      pageTitle: 'Request for Session Detail',
      breadcrumb: [
        {
          text: 'Sessions Management',
        },
        {
          text: 'Request for Session',
          to: '/sessions-management/request-sessions'
        },
      ],
    },
  }, 
  {
    path: '/user-management/tutor-verification',
    name: 'tutor-verification-mgmt',
    component: () => import(/* webpackChunkName: "[request]" */'@/views/Users/TutorVerification/tutorVerificationList.vue'),
    meta: {
      pageTitle: 'Tutor Verification',
      breadcrumb: [
        {
          text: 'User Management',
        },
        {
          text: 'Tutor Verification',
          active: true     
        },
      ],
    },
  },
  {
    path: '/sessions-management/all-sessions',
    name: 'all-session-mgmt',
    component: () => import(/* webpackChunkName: "[request]" */'@/views/SessionsManagement/AllSession(s)/allSessionsList.vue'),
    meta: {
      pageTitle: 'All Session(s)',
      breadcrumb: [
        {
          text: 'Sessions Management',
        },
        {
          text: 'All Session(s)',
          active:true
        },
      ],
    },
  },
  {
    path: '/sessions-management/all-sessions/:id',
    name: 'session-details',
    component: () => import(/* webpackChunkName: "[request]" */'@/views/SessionsManagement/AllSession(s)/sessionDetail.vue'),
    meta: {
      pageTitle: 'Session Details',
      breadcrumb: [
        {
          text: 'Sessions Management',
        },
        {
          text: 'All Session(s)',
          to:'/sessions-management/all-sessions'
        },
      ],
    },
  },
  {
    path: '/user-management/tutor-verification/:id',
    name: 'tutor-verification-details',
    component: () => import(/* webpackChunkName: "[request]" */'@/views/Users/TutorVerification/tutorVerificationDetail.vue'),
    meta: {
      pageTitle: 'Tutor Verification Details',
      breadcrumb: [
        {
          text: 'User Management',
        },
        {
          text: 'Tutor Verification',
          to:'/user-management/tutor-verification'
        }
      ]
    },
  },
  {
    path: '/sessions-booking-management/cancelled-sessions',
    name: 'canceled-session-mgmt',
    component: () => import(/* webpackChunkName: "[request]" */'@/views/SessionsManagement/CanceledSession(s)/canceledSessionsList.vue'),
    meta: {
      pageTitle: 'Cancelled Session(s)',
      breadcrumb: [
        {
          text: 'Sessions Booking Management',
        },
        {
          text: 'Cancelled Session(s)',
          active:true
        }
      ],
    },
  },
  {
    path: '/complaint-management/complaint-by-learner',
    name: 'complaint-learner-mgmt',
    component: () => import(/* webpackChunkName: "[request]" */'@/views/ComplaintManagement/Complaintlearner.vue'),
    meta: {
      pageTitle: 'Complaint by Learner ',
      breadcrumb: [
        {
          text: 'Complaint Management',
        },
        {
          text: 'Complaint by Learner ',
          active:true
        },
      ]
    }
  },
  {
    path: '/sessions-booking-management/current-online-sessions',
    name: 'current-online-session-mgmt',
    component: () => import(/* webpackChunkName: "[request]" */'@/views/SessionsManagement/CurrentOnlineSession(s)/currentOnlineSessionsList.vue'),
    meta: {
      pageTitle: 'Current Online Session(s)',
      breadcrumb: [
        {
          text: 'Sessions Booking Management',
        },
        {
          text: 'Current Online Session(s)',
          active:true
        }
      ]
    }
  },
  {
    path: '/sessions-booking-management/upcoming-sessions',
    name: 'upcoming-session-mgmt',
    component: () => import(/* webpackChunkName: "[request]" */'@/views/SessionsManagement/UpcomingSession(s)/upcomingSessionList.vue'),
    meta: {
      pageTitle: 'Upcoming Session(s)',
      breadcrumb: [
        {
          text: 'Sessions Booking Management',
        },
        {
          text: 'Upcoming Session(s)',
          active:true
        }
      ]
    }
  },
  {
    path: '/sessions-booking-management/past-sessions',
    name: 'past-session-mgmt',
    component: () => import(/* webpackChunkName: "[request]" */'@/views/SessionsManagement/pastSession(s)/pastSessionList.vue'),
    meta: {
      pageTitle: 'Past Session(s)',
      breadcrumb: [
        {
          text: 'Sessions Booking Management',
        },
        {
          text: 'Past Session(s)',
          active:true
        }
      ]
    }
  },
  {
    path: '/sessions-booking-management/cancellation-requests',
    name: 'cancellation-session-details',
    component: () => import(/* webpackChunkName: "[request]" */'@/views/SessionsManagement/CanceledSession(s)/cancellationRequestDetail.vue'),
    meta: {
      pageTitle: 'Cancellation Request(s)',
      breadcrumb: [
        {
          text: 'Sessions Booking Management',
        },
        {
          text: 'Cancellation Request(s)',
          active:true
        }
      ]
    }
  },
  {
    path: '/sessions-booking-management/pending-sessions',
    name: 'pending-session-mgmt',
    component: () => import(/* webpackChunkName: "[request]" */'@/views/SessionsManagement/PendingSession(s)/pendingSessionList.vue'),
    meta: {
      pageTitle: 'Pending Session(s)',
      breadcrumb: [
        {
          text: 'Sessions Booking Management',
        },
        {
          text: 'Pending Session(s)',
          active:true
        }
      ]
    }
  },
  {
    path: '/sessions-booking-management/rejected-sessions',
    name: 'rejected-session-mgmt',
    component: () => import(/* webpackChunkName: "[request]" */'@/views/SessionsManagement/RejectedSession(s)/rejectedSessionList.vue'),
    meta: {
      pageTitle: 'Rejected Session(s)',
      breadcrumb: [
        {
          text: 'Sessions Booking Management',
        },
        {
          text: 'Rejected Session(s)',
          active:true
        }
      ]
    }
  },
  {
    path: '/sessions-booking-management/past-sessions/:id',
    name: 'past-session-details',
    component: () => import(/* webpackChunkName: "[request]" */'@/views/SessionsManagement/pastSession(s)/pastSessionDetail.vue'),
    meta: {
      pageTitle: 'Past Session Details',
      breadcrumb: [
        {
          text: 'Sessions Booking Management',
        },
        {
          text: 'Past Session(s)',
          to: '/sessions-booking-management/past-sessions'
        }        
      ],
    },
  },
  {
    path: '/complaint-management/complaint-by-tutor',
    name: 'complaint-tutor-mgmt',
    component: () => import(/* webpackChunkName: "[request]" */'@/views/ComplaintManagement/Complainttutor.vue'),
    meta: {
      pageTitle: 'Complaint by Tutor ',
      breadcrumb: [
        {
          text: 'Complaint Management',
        },
        {
          text: 'Complaint by Tutor ',
          active:true
        },
      ],
    }
  },
  {
    path: '/review-management',
    name: 'review-management-mgmt',
    component: () => import(/* webpackChunkName: "[request]" */'@/views/Reviewmanagement.vue'),
    meta: {
      pageTitle: 'Review Management ',
      breadcrumb: [        
        {
          text: 'Review Management',
          active:true
        },
      ],
    }
  },
  {
    path: '/sessions-booking-management/current-online-sessions/:id',
    name: 'current-session-detail',
    component: () => import(/* webpackChunkName: "[request]" */'@/views/SessionsManagement/CurrentOnlineSession(s)/currentOnlineSessionDetail.vue'),
    meta: {
      pageTitle: 'Current Online Details',
      breadcrumb: [
        {
          text: 'Sessions Booking Management',
        },
        {
          text: 'Current Online Session(s)',
          to :  '/sessions-booking-management/current-online-sessions'
        },
      ],
    },
  },
  {
    path: '/sessions-booking-management/upcoming-sessions/:id',
    name: 'upcoming-session-details',
    component: () => import(/* webpackChunkName: "[request]" */'@/views/SessionsManagement/UpcomingSession(s)/upcomingsessionDetail.vue'),
    meta: {
      pageTitle: 'Upcoming Session Details',
      breadcrumb: [
        {
          text: 'Sessions Booking Management',
        },
        {
          text: 'Upcoming Session(s)',
          to: '/sessions-booking-management/upcoming-sessions'
        },
      ],
    },
  },
  {
    path: '/booking-detail-transection-learner/:id',
    name: 'booking-detail-transection-learner',
    component: () => import(/* webpackChunkName: "[request]" */'@/views/TransactionHistory/transectionBookingDetailPage.vue'),
    meta: {
      pageTitle: 'Transaction Booking Details',
      breadcrumb: [
        {
          text: 'Transaction Management',
        },
        {
          text: 'All Transactions',
          to: '/transaction-management/transaction-history'
        },
      ],
    },
  },
  {
    path: '/sessions-booking-management/pending-sessions/:id',
    name: 'booking-detail-pending',
    component: () => import(/* webpackChunkName: "[request]" */'@/views/TransactionHistory/transectionBookingDetailPage.vue'),
    meta: {
      pageTitle: 'Pending Session Details',
      breadcrumb: [
        {
          text: 'Sessions Booking Management',
        },
        { 
          text: 'Pending Session(s)',
          to:'/sessions-booking-management/pending-sessions'
        },
      ],
    },
  },
  {
    path: '/sessions-booking-management/rejected-sessions/:id',
    name: 'booking-detail-reject',
    component: () => import(/* webpackChunkName: "[request]" */'@/views/TransactionHistory/transectionBookingDetailPage.vue'),
    meta: {
      pageTitle: 'Rejected Session Details',
      breadcrumb: [
        {
          text: 'Sessions Booking Management',
        },
        { 
          text: 'Rejected Session(s)',
          to: '/sessions-booking-management/rejected-sessions'
        },
      ],
    },
  },
  {
    path: '/booking-detail-transection-tutor/:id',
    name: 'booking-detail-transection-tutor',
    component: () => import(/* webpackChunkName: "[request]" */'@/views/TransactionHistory/transectionBookingDetailPage.vue'),
    meta: {
      pageTitle: 'Transaction Booking Details',
      breadcrumb: [
        {
          text: 'Transaction Management',
        },
        { 
          text: 'All Transactions',
          to: '/transaction-management/transaction-history'
        },
      ],
    },
  },
  {
    path: '/complaint-management/complaint-by-learner/:id',
    name: 'booking-detail-complaint-learner',
    component: () => import(/* webpackChunkName: "[request]" */'@/views/TransactionHistory/transectionBookingDetailPage.vue'),
    meta: {
      pageTitle: 'Complaint Booking Details',
      breadcrumb: [
        {
          text: 'Complaint Management',
        },
        { 
          text: 'Complaint by Learner',
          to: '/complaint-management/complaint-by-learner'
        },
      ],
    },
  },
  {
    path: '/complaint-management/complaint-by-tutor/:id',
    name: 'booking-detail-complaint-tutor',
    component: () => import(/* webpackChunkName: "[request]" */'@/views/TransactionHistory/transectionBookingDetailPage.vue'),
    meta: {
      pageTitle: 'Complaint Booking Details',
      breadcrumb: [
        {
          text: 'Complaint Management',
        },
        { 
          text: 'Complaint by Tutor',
          to: '/complaint-management/complaint-by-tutor'
        },
      ],
    },
  },
  {
    path: '/sessions-booking-management/cancelled-sessions/:id',
    name: 'cancel-session-details',
    component: () => import('@/views/SessionsManagement/CanceledSession(s)/cancelSessionDetail.vue'),
    meta: {
      pageTitle: 'Cancelled Session Details',
      breadcrumb: [
        {
          text: 'Sessions Booking Management',
        },
        {
          text: 'Cancelled Session(s)',
          to: '/sessions-booking-management/cancelled-sessions'
        },
      ],
    },
  },
  {
    path: '/sessions-booking-management/cancellation-requests/:id',
    name: 'cancel-session-request-details',
    component: () => import('@/views/SessionsManagement/CanceledSession(s)/cancelSessionRequestDetail.vue'),
    meta: {
      pageTitle: 'Cancellation Request Details',
      breadcrumb: [
        {
          text: 'Sessions Booking Management',
        },
        {
          text: 'Cancellation Request(s)',
          to: '/sessions-booking-management/cancellation-requests'
        },
      ],
    },
  },
  {
    path: '/addData',
    name: 'addData',
    component: () => import(/* webpackChunkName: "[request]" */'@/views/addDataForm.vue'),
    meta: {
      pageTitle: 'addData',
      breadcrumb: [
        {
          text: 'addData',
          active:true
        },
      ],
    },
  },
  {
    path: '/versionControl',
    name: 'versionControl',
    component: () => import(/* webpackChunkName: "[request]" */'@/views/versionControl.vue'),
    meta: {
      pageTitle: 'Version Control',
      breadcrumb: [
        {
          text: 'Version Control',
          active:true
        },
      ],
    },
  },
  {
    path: '/banner-management',
    name: 'banner-management-mgmt',
    component: () => import(/* webpackChunkName: "[request]" */'@/views/Bannermanagement.vue'),
    meta: {
      pageTitle: 'Banner Management',
      breadcrumb: [        
        {
          text: 'Banner Management',
          active:true
        },
      ],
    }
  },
  {
    path: '/category-management/category',
    name: 'category-mgmt',
    component: () => import(/* webpackChunkName: "[request]" */'@/views/CategoryManagement/Category.vue'),
    meta: {
      pageTitle: 'Category',
      breadcrumb: [ 
        {
          text: 'Category Management',
        },       
        {
          text: 'Category',
          active:true
        },
      ],
    }
  },
  {
    path: '/category-management/category/:id',
    name: 'category-details',
    component: () => import(/* webpackChunkName: "[request]" */'@/views/CategoryManagement/Categorydetail.vue'),
    meta: {
      pageTitle: 'Category Details',
      breadcrumb: [
        {
          text: 'Category Management',
        },
      ],
    },
  },
  {
    path: '/settings/school-management',
    name: 'school-mgmt',
    component: () => import(/* webpackChunkName: "[request]" */'@/views/Setting/Schoolmanagement.vue'),
    meta: {
      pageTitle: 'School Management',
      breadcrumb: [  
        {
          text: 'Settings',
        },      
        {
          text: 'School Management',
          active:true
        },
      ],
    }
  },
  {
    path: '/curriculum-management/curriculam',
    name: 'syllabus-mgmt',
    component: () => import(/* webpackChunkName: "[request]" */'@/views/CurriculamManagement/Syllabus.vue'),
    meta: {
      pageTitle: 'Curriculum',
      breadcrumb: [    
        {
          text: 'Curriculum Management',
        },    
        {
          text: 'Curriculum',
          active:true
        },
      ],
    }
  },
  {
    path: '/curriculum-management/grade',
    name: 'grade-mgmt',
    component: () => import(/* webpackChunkName: "[request]" */'@/views/CurriculamManagement/Grade.vue'),
    meta: {
      pageTitle: 'Grade',
      breadcrumb: [    
        {
          text: 'Curriculum Management',
        },    
        {
          text: 'Grade',
          active:true
        },
      ],
    }
  },
  {
    path: '/settings/email-template',
    name: 'email-template',
    component: () => import(/* webpackChunkName: "[request]" */'@/views/Setting/Emailtemplate.vue'),
    meta: {
      pageTitle: 'Email Template',
      breadcrumb: [    
        {
          text: 'Settings',
        },    
        {
          text: 'Email Template',
          active:true
        },
      ],
    }
  },  
  {
    path: '/setting/create-email-template',
    name: 'create-email-template',
    component: () => import(/* webpackChunkName: "[request]" */'@/views/Setting/Createemailtemplate.vue'),
    meta: {
      pageTitle: 'Create Email Template',
      breadcrumb: [    
        {
          text: 'Settings',
        }, 
        {
          text:'Email Template',
          to : '/settings/email-template'
        },
        {
          text: 'Create Email Template',
          active:true
          
        },
      ],
    }
  },
  {
    path: '/setting/edit-email-template/:id',
    name: 'edit-email-template',
    component: () => import(/* webpackChunkName: "[request]" */'@/views/Setting/Createemailtemplate.vue'),
    meta: {
      pageTitle: 'Edit Email Template',
      breadcrumb: [    
        {
          text: 'Settings',
        }, 
        {
          text:'Email Template',
          to : '/settings/email-template'
        },
        {
          text: 'Edit Email Template',
          active:true
          
        },
      ],
    }
  },
  {
    path: '/settings/notification-management',
    name: 'notification-mgmt',
    component: () => import(/* webpackChunkName: "[request]" */'@/views/Setting/Notificationmgmt.vue'),
    meta: {
      pageTitle: 'Notification Management',
      breadcrumb: [    
        {
          text: 'Settings',
        }, 
        {
          text: 'Notification Management',
          active:true
        },
      ],
    }
  },
  {
    path: '/settings/faq',
    name: 'faq',
    component: () => import(/* webpackChunkName: "[request]" */'@/views/Setting/Faq.vue'),
    meta: {
      pageTitle: 'FAQ',
      breadcrumb: [    
        {
          text: 'Settings',
        }, 
        {
          text: 'FAQ',
          active:true
        },
      ],
    }
  },
  {
    path: '/settings/contact-us',
    name: 'contact_us',
    component: () => import(/* webpackChunkName: "[request]" */'@/views/Setting/Contact_us.vue'),
    meta: {
      pageTitle: 'Contact us',
      breadcrumb: [    
        {
          text: 'Settings',
        }, 
        {
          text: 'Contact Us',
          active:true
        },
      ],
    }
  },    
  {
    path: '/settings/plan-management',
    name: 'plan-mgmt',
    component: () => import(/* webpackChunkName: "[request]" */'@/views/Setting/Planmanagement.vue'),
    meta: {
      pageTitle: 'Plan Management',
      breadcrumb: [    
        {
          text: 'Settings',
        }, 
        {
          text: 'Plan Management',
          active:true
        },
      ],
    }
  },    
  {
    path: '/settings/create-plan',
    name: 'create-plan',
    component: () => import(/* webpackChunkName: "[request]" */'@/views/Setting/Createplan.vue'),
    meta: {
      pageTitle: 'Create Plan',
      breadcrumb: [    
        {
          text: 'Settings',
        }, 
        {
          text:'Plan Management',
          to: '/settings/plan-management'
        },
        {
          text: 'Create Plan',
          active:true
        },
      ],
    }
  },
  {
    path: '/settings/edit-plan/:id',
    name: 'edit-plan',
    component: () => import(/* webpackChunkName: "[request]" */'@/views/Setting/Editplan.vue'),
    meta: {
      pageTitle: 'Edit Plan',
      breadcrumb: [    
        {
          text: 'Settings',
        }, 
        {
          text:'Plan Management',
          to: '/settings/plan-management'
        },
        {
          text: 'Edit Plan',
          active:true
        },
      ],
    }
  },
  {
    path: '/settings/terms-and-condition',
    name: 'terms&condition',
    component: () => import(/* webpackChunkName: "[request]" */'@/views/Setting/Termscondition.vue'),
    meta: {
      pageTitle: 'Terms and Conditions',
      breadcrumb: [    
        {
          text: 'Settings',
        }, 
        {
          text: 'Terms and Conditions',
          active:true
        },
      ],
    }
  },   
  {
    path: '/settings/privacy-policy',
    name: 'privacypolicy',
    component: () => import(/* webpackChunkName: "[request]" */'@/views/Setting/Privacypolicy.vue'),
    meta: {
      pageTitle: 'Privacy Policy',
      breadcrumb: [    
        {
          text: 'Settings',
        }, 
        {
          text: 'Privacy Policy',
          active:true
        },
      ],
    }
  },   
  {
    path: '/settings/general-settings',
    name: 'generalsettings',
    component: () => import(/* webpackChunkName: "[request]" */'@/views/Setting/Generalsettings.vue'),
    meta: {
      pageTitle: 'General Settings',
      breadcrumb: [    
        {
          text: 'Settings',
        }, 
        {
          text: 'General Settings',
          active:true
        },
      ],
    }
  },
  {
    path: '/settings/payment-gateway-management',
    name: 'paymentgateways',
    component: () => import(/* webpackChunkName: "[request]" */'@/views/Setting/Paymentgetwaymgmt.vue'),
    meta: {
      pageTitle: 'Payment Gateway Management',
      breadcrumb: [
        {
          text: 'Settings',
        }, 
        {
          text: 'Payment Gateway Management',
          active:true
        },
      ],
    }
  },    
  {
    path: '/user/user-mgmt/upcoming-detail/:id',
    name: 'upcoming-detail',
    component: () => import(/* webpackChunkName: "[request]" */'@/views/Users/User/Upcomingdetail.vue'),
    meta: {
      pageTitle: 'Upcoming Details',
      breadcrumb: [
        {
          text: 'User Management',
        },
        {
          text: 'User',
        },
        {
          text: 'Angela Mayer',
        }
       
      ],
    },
  },
  {
    path: '/user/user-mgmt/past-session-detail/:id',
    name: 'past-session-detail',
    component: () => import(/* webpackChunkName: "[request]" */'@/views/Users/User/Pastsessiondetail.vue'),
    meta: {
      pageTitle: 'Past Session Details',
      breadcrumb: [
        {
          text: 'User Management',
        },
        {
          text: 'User',
        },
        {
          text: 'Angela Mayer',
        }
       
      ],
    },
  },
  {
    path: '/user/user-mgmt/canceled-session-detail/:id',
    name: 'canceled-session-detail',
    component: () => import(/* webpackChunkName: "[request]" */'@/views/Users/User/Canceledsessiondetail.vue'),
    meta: {
      pageTitle: 'Canceled Session Details',
      breadcrumb: [
        {
          text: 'User Management',
        },
        {
          text: 'User',
        },
        {
          text: 'Angela Mayer',
        }
       
      ],
    },
  },
  {
    path: '/user-management/users/create-user',
    name: 'create-user',
    component: () => import(/* webpackChunkName: "[request]" */'@/views/Users/User/Createuser.vue'),
    meta: {
      pageTitle: 'Create User',
      breadcrumb: [
        {
          text: 'User Management',          
        },
        {
          text: 'User',
          to: '/user-management/users'
        },
        {
          text: 'Create User',
          active:true
        }
       
      ],
    },
  },
  {
    path: '/user-management/users/:id',
    name: 'user-details',
    component: () => import(/* webpackChunkName: "[request]" */'@/views/Users/User/userDetail.vue'),
    meta: {
      pageTitle: 'User Details',
      breadcrumb: [
        {
          text: 'User Management',
        },
        {
          text: 'User',
          to: '/user-management/users'
        },
       
      ],
    },
  },
  {
    path: '/user-management/users/edit-user/:id',
    name: 'edit-user',
    component: () => import(/* webpackChunkName: "[request]" */'@/views/Users/User/Edituser.vue'),
    meta: {
      pageTitle: 'Edit User',
      breadcrumb: [
        {
          text: 'User Management',
        },
        {
          text: 'User',
          to: '/user-management/users'
        },
      ],
    },
  },  
  {
    path: '/user-management/users/edit-tutor-user/:id',
    name: 'edit-tutor-user',
    component: () => import(/* webpackChunkName: "[request]" */'@/views/Users/User/Edituser_Tutor.vue'),
    meta: {
      pageTitle: 'Edit Tutor',
      breadcrumb: [
        {
          text: 'User Management',
        },
        {
          text: 'User',
          to: '/user-management/users'
        },  
      ],
    },
  },  
  {
    path: '/user/user-mgmt/edit-bank-account',
    name: 'edit-bank-account',
    component: () => import(/* webpackChunkName: "[request]" */'@/views/Users/User/Editbankaccount.vue'),
    meta: {
      pageTitle: 'Edit Bank Account',
      breadcrumb: [
        {
          text: 'User Management',
        },
        {
          text: 'User',
        },
        {
          text: 'Edit Bank Account',
          active:true
        }
       
      ],
    },
  },  
  {
    path: '/user/user-mgmt/edit-qualification-document',
    name: 'edit-qualification-document',
    component: () => import(/* webpackChunkName: "[request]" */'@/views/Users/User/Editqualificationdocument.vue'),
    meta: {
      pageTitle: 'Edit Qualification Document',
      breadcrumb: [
        {
          text: 'User Management',
        },
        {
          text: 'User',
        },
        {
          text: 'Edit Qualification Document',
          active:true
        }
       
      ],
    },
  },
  {
    path: '/user/user-mgmt/edit-identity-document',
    name: 'edit-identity-document',
    component: () => import(/* webpackChunkName: "[request]" */'@/views/Users/User/Editdentitydocument.vue'),
    meta: {
      pageTitle: 'Edit Identity Document',
      breadcrumb: [
        {
          text: 'User Management',
        },
        {
          text: 'User',
        },
        {
          text: 'Edit Identity Document',
          active:true
        }
       
      ],
    },
  },
  {
    path: '/user/user-mgmt/booking-session',
    name: 'booking-session',
    component: () => import(/* webpackChunkName: "[request]" */'@/views/Users/User/BookingSession/Bookingsession.vue'),
    meta: {
      pageTitle: 'Booking Session',
      breadcrumb: [
        {
          text: 'User Management',
        },
        {
          text: 'User',
        },
        {
          text: 'Booking Session',
          active:true
        }
       
      ],
    },
  },
  {
    path: '/user/user-mgmt/booking-session-learner',
    name: 'booking-session-learner',
    component: () => import(/* webpackChunkName: "[request]" */'@/views/Users/User/BookingSession/Bookingsession_Learner.vue'),
    meta: {
      pageTitle: 'Booking Session',
      breadcrumb: [
        {
          text: 'User Management',
        },
        {
          text: 'User',
        },
        {
          text: 'Booking Session',
          active:true
        }
       
      ],
    },
  },
  {
    path: '/user/user-mgmt/booking-session-tutor',
    name: 'booking-session-tutor',
    component: () => import(/* webpackChunkName: "[request]" */'@/views/Users/User/BookingSession/Bookingsession_Tutor.vue'),
    meta: {
      pageTitle: 'Booking Session',
      breadcrumb: [
        {
          text: 'User Management',
        },
        {
          text: 'User',
        },
        {
          text: 'Booking Session',
          active:true
        }
       
      ],
    },
  },
  {
    path: '/user/user-mgmt/booking-session-learner-tutor',
    name: 'booking-session-learner-tutor',
    component: () => import(/* webpackChunkName: "[request]" */'@/views/Users/User/BookingSession/Bookingsession_Learner_Tutor.vue'),
    meta: {
      pageTitle: 'Booking Session',
      breadcrumb: [
        {
          text: 'User Management',
        },
        {
          text: 'User',
        },
        {
          text: 'Booking Session',
          active:true
        }
        
       
      ],
    },
  },
  {
    path: '/booking-session-all-session',
    name: 'booking-session-all-session-new',
    component: () => import(/* webpackChunkName: "[request]" */'@/views/Users/User/BookingSession/Bookingsession_all_session.vue'),
    meta: {
      pageTitle: 'Booking Session',
      breadcrumb: [
        {
          text: 'User Management'
        },
        {
          text: 'User',
          to: '/user-management/users'
        },
        {
          text: 'Booking Session',
          active:true
        }
       
      ],
    },
  },
  {
    path: '/booking-session-all-session/:id',
    name: 'booking-session-all-session',
    component: () => import(/* webpackChunkName: "[request]" */'@/views/Users/User/BookingSession/Bookingsession_all_session.vue'),
    meta: {
      pageTitle: 'Booking Session',
      breadcrumb: [
        {
          text: 'User Management',
        },
        {
          text: 'User',
          to:'/user-management/users'
        },
        {
          text: 'Booking Session',
          active:true
        }
       
      ],
    },
  },
  {
    path: '*',
    name: 'error-404',
    meta: {
      title:'Error'
    },
    component: () =>  
        import ( /* webpackChunkName: "about" */ '@/views/error/Error404.vue')
  },
  {
    path: '/transaction-management/withdraw-request',
    name: 'withdraw-request',
    component: () => import(/* webpackChunkName: "[request]" */'@/views/TransactionHistory/withdrawRequest.vue'),
    meta: {
      pageTitle: 'Withdraw Request',
      breadcrumb: [   
        {
          text: 'Transaction Management',
        },
        {
          text: 'Withdraw Request',
          active:true
        },
      ],
    }
  },
  {
    path: '/transaction-management/transaction-history',
    name: 'transaction-history',
    component: () => import(/* webpackChunkName: "[request]" */'@/views/TransactionHistory/transactionHistory.vue'),
    meta: {
      pageTitle: 'Transaction History',
      breadcrumb: [ 
        {
          text: 'Transaction Management',
        },       
        {
          text: 'All Transactions',
          active:true
        },
      ],
    }
  },
 
]
export default data
