import firebase from "@/utils/firebaseInit"
import { dbCollections } from "@/utils/firebaseCollection"
const db = firebase.firestore();

export default {
    namespaced: true,
    state: {
        planManagament: []
    },
    getters: {
        getPlanManagament: state => state.planManagament,

    },
    mutations: {
        setPlanManagamentArr(state, payload) {
            state.planManagament = payload;
        },
        testMutation(state, payload) {
            state.planManagament.splice(payload, 1)
        }
    },
    actions: {
        getPlanManagamentData(state) {
            return new Promise((resolve, reject) => {
                try {
                    var planManagament = [];
                    db.collection(dbCollections.SETTINGS)
                        .doc('setting')
                        .collection(dbCollections.PLAN)
                        .orderBy('planName', 'desc')
                        .get().then(snapshot => {
                            planManagament = [];
                            if (snapshot.empty) {
                                resolve("No Data Found.");
                            }
                            else {
                                snapshot.forEach(doc => {
                                    if (!doc.data().isDeleted) {
                                        planManagament.push({ id: doc.id, ...doc.data() });
                                    }
                                });
                                state.commit("setPlanManagamentArr", planManagament);

                                if (planManagament.length == 0) {
                                    resolve("No Data Found.");
                                }
                                else {
                                    resolve("Records Found.");
                                };
                            }
                        });
                }
                catch (error) {
                    reject('error', error);
                }
            })
        }

    }
}