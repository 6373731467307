import firebase from "@/utils/firebaseInit"
import { dbCollections } from "@/utils/firebaseCollection"
const db = firebase.firestore();

export default {
  namespaced: true,
  state: {
    review:[]
  },
  getters: {
    getPastSession: state => state.review
  },
  mutations: {
    setPastSession(state,payload){
      state.review = payload;
    },
  },
  actions: {
    getPastSessionData(state){
      return new Promise((resolve,reject)=>{
        try{
          let review = [];
          db.collection(dbCollections.BOOKING).where('status', '==' , 4).where('endTime','<',new Date()).get().then(snapshot=>{
              review = [];              
              if(snapshot.empty){
                resolve("No Data Found.");
              }
              else
              {
                snapshot.forEach(doc=>{
                  if(doc.data())
                  review.push(doc.data());
                });
                state.commit("setPastSession",review);

                if(review.length == 0)
                {
                  resolve("No Data Found.");
                }
                else
                {
                  resolve("Records Found.");
                };
              }

            },
            (error)=>{
              console.error("error",error);
            });
        }
        catch(error){
          console.error("error",error);
          reject(error);
        };
      });
    }
  },
}
