import Vue from 'vue'
import Vuex from 'vuex'


import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'
import userManagement from './userManagement'
import categoryManagement from './categoryManagement'
import RevanueCalculation from './RevanueCalculation'
import faqManagement from './faqManagement'
import sesionManagement from './sessionManagement'
import bannerManagement from './bannerManagement'
import reviews from './reviews'
import requestCategory from './requestCategory'
import settings from './settings'
import emailTemplate from './emailTemplate'
import complainBtTutorManagement from './complaintByTutorManagement'
import booking from './booking'
import status from './status'
import notificationManagement from './notificationManagement'
import termsAndCondition from './termsAndCondition'
import planManagament from './planManagament'
import upComingSession from './upComingSession'
import currentOnline from './currentOnline'
import cancelSessionDetails from './cancelSessionDetails'
import pastSessionManagement from './pastSessionManagement'
import dashboardReviewRequest from './dashboardReviewRequest'
Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app,
    appConfig,
    verticalMenu,
    userManagement,
    faqManagement,
    categoryManagement,
    RevanueCalculation,
    sesionManagement,
    bannerManagement,
    reviews,
    requestCategory,
    emailTemplate,
    settings,
    complainBtTutorManagement,
    booking,
    notificationManagement,
    status,
    termsAndCondition,
    planManagament,
    upComingSession,
    currentOnline,
    cancelSessionDetails,
    pastSessionManagement,
    dashboardReviewRequest
  },
  getters:{
    getRoutes: state=> state
  },
  strict: process.env.DEV,
})
