import firebase from "@/utils/firebaseInit"
import { dbCollections } from "@/utils/firebaseCollection"
import * as getQuery from "@/utils/FirebaseQueries/getQueries/getQueries.js";

const db = firebase.firestore();
import moment from "moment"

export const getRevanue =({commit})=>{
    return new Promise((resolve,reject)=>{
        try{
            db.collection(dbCollections.AMOUNTTRANSECTION).where("status","==",2).get().then((data)=>{
                if (data.length == 0) {
                    resolve("No Data Found")
                } else{
                    let obj = {
                         totalRevenue: 0,
                         totalExpense: 0,
                         totalProfit: 0
                    }
                    let amountTransection = []
                    data.forEach((transection)=>{
                        let amountTransectionObj = {}
                        amountTransection.push(amountTransectionObj);
                        if (transection.data().transactionType === "cr") {
                            obj.totalExpense += transection.data().amount ? transection.data().amount : 0
                        }
                        if (transection.data().transactionType === "dr") {
                            obj.totalRevenue += transection.data().amount ? transection.data().amount : 0
                            amountTransectionObj.amount = transection.data().amount,
                            amountTransectionObj.date = moment(new Date( transection.data().createdAt.seconds * 1000)).format('DD-MM-YYYY'),
                            amountTransectionObj.transactionType = transection.data().transactionType
                        }
                        obj.totalProfit = obj.totalRevenue - obj.totalExpense
                        
                    })
                    commit('setRevanueCalc',obj);
                    commit('setAmountTransectionDate',amountTransection);
                    resolve("Records Found.")
                }
            }).catch((error)=>{
                console.error("error",error);
                reject(error);    
            })
        }catch(error){
            console.error("error",error);
            reject(error);
        }
    })
};


export const getRevanueGraph =({commit},{startingDate,endingDate})=>{
    return new Promise((resolve,reject)=>{
        try {
            let totalDate = [];
            let startDate = new Date(startingDate).getTime();
            let endDate = new Date(endingDate).getTime();
            if(startDate == endDate){
                let addDate = new Date(startDate)
                totalDate.push(addDate);
                totalDate.push(addDate);
            }else{
                while(startDate < endDate){
                    let addDate = new Date(startDate)
                    totalDate.push(addDate);
                    var newDate = moment(startDate).add(1,'days')
                    startDate = new Date(newDate).getTime()
                }
                let addDate = new Date(endDate)
                totalDate.push(addDate);
            }
            let array = []
            let data = []
            let expense = []
            let profit = []
            let count = 0;
            let countFun = (raw) => {
                if (count >= totalDate.length-1) {
                    let obj = {
                        label : array,
                        revanue : data,
                        expense : expense,
                        profit : profit
                    }
                    commit('setRevanueGraph',obj);
                    resolve("Graph Records Found.")
                  return;
                }
                else {
                    let date = moment(raw).format("DD-MM")
                    let month = moment(raw).format("YYYY-MM")
                    array.push(date)
                     db.collection(dbCollections.UPDATEGRAPHDATA).doc(month).collection("day").doc(date).get().then((revanue)=>{
                       data.push(revanue.data() && revanue.data().revanue ? revanue.data().revanue : 0)
                       expense.push(revanue.data() && revanue.data().expense ? revanue.data().expense : 0)
                       profit.push(revanue.data() && revanue.data().profit ? revanue.data().profit : 0)
                       count+= 1;
                       countFun(totalDate[count]);
                     }).catch(error=>{
                         console.error(error);
                         count+= 1;
                         countFun(totalDate[count]);
                     })
                }
            }
            countFun(totalDate[count]);
        } catch(error) {
            reject(error);
        }
    })
}

export const getPlanGraph =({commit},{startingDate,endingDate})=>{
    return new Promise((resolve,reject)=>{
        try {
            let stepSize = 0;
            let RoundFigureSize = 0;
            let label = [];
            let dataSet = [];
            let commitss = {};
            let totalDate = [];
            let startDate = new Date(startingDate).setHours(0,0,0,0);
            let endDate = new Date(endingDate).setHours(0,0,0,0);
            if(startDate == endDate){
                let addDate = new Date(startDate)
                totalDate.push(addDate);
                totalDate.push(addDate);
            }else{
                while(startDate < endDate){
                    let addDate = new Date(startDate)
                    totalDate.push(addDate);
                    var newDate = moment(startDate).add(1,'days')
                    startDate = new Date(newDate).getTime()
                }
                let addDate = new Date(endDate);
                totalDate.push(addDate);
            }
            let array = []
            totalDate.forEach((date)=>{
                array.push(moment(date).format("DD-MM"))
              })
            let count = 0;
            let planCount = 0;
             let planName = {};
            let colors = ['#F080A4','#28DAC6','#FFA800','#7367F0','#28C76F','#FFED33','#13fc03','#030ffc','#fc03d7','#665f31',
              '#1dde64','#d11541','#f76605','#0f0f0f','#2b7328'];
            let maxPlan = [];
            let countFun = (raw) => {
              if(count >= totalDate.length) {
                for (let i = 0; i < Object.keys(planName).length; i++) {                 
                  let dataDisplay = [];
                  for (let j = 0; j < array.length; j++) {
                    let count = 0;
                    for (let k = 0; k < planName[Object.keys(planName)[i]].length; k++) {
                      if (array[j] === planName[Object.keys(planName)[i]][k].date) {
                        count = planName[Object.keys(planName)[i]][k].plan
                        break;
                      }
                    }
                    dataDisplay.push(count)
                  }
                     let obj = { 
                      data: dataDisplay,
                      borderColor: colors[i] ? colors[i] : (colors[i-15] ?  colors[i-15] :  '#666ee8'),
                      label: Object.keys(planName)[i],
                      lineTension: 0.5,
                      pointStyle: 'circle',
                      backgroundColor: colors[i] ? colors[i] : (colors[i-15] ?  colors[i-15] :  '#666ee8'),
                      fill: false,
                      pointRadius: 2,
                      pointHoverRadius: 5,
                      pointHoverBorderWidth: 5,
                      pointBorderColor: 'transparent',
                      pointHoverBorderColor: '#fff',
                      pointHoverBackgroundColor: colors[i] ? colors[i] : (colors[i-15] ?  colors[i-15] :  '#666ee8'),
                      pointShadowOffsetX: 1,
                      pointShadowOffsetY: 1,
                      pointShadowBlur: 5,
                      pointShadowColor: 'rgba(0, 0, 0, 0.25)',
                    }
                    dataSet.push(obj);
                    let max = Math.max(...dataDisplay)
                    maxPlan.push(max);
                }
                let maximum = [Math.max(...maxPlan)]
                let maximumNumber = Math.max(...maximum)
                let RoundFigure = Math.ceil(maximumNumber / 10) * 10
                let step = Math.ceil(RoundFigure / 10)
                stepSize = step
                RoundFigureSize = RoundFigure
                label = array
                commitss = {
                    step: stepSize,
                    RoundFigure : RoundFigureSize,
                    labels : label,
                    data: dataSet,
                    planCount: planCount
                }
                commit('setPlanGraph',commitss);
                resolve("Graph Records Found.")
                return;
              }
              else {
                  let date = moment(raw).format("DD-MM")
                  let month = moment(raw).format("YYYY-MM")
                   db.collection(dbCollections.UPDATEGRAPHDATA).doc(month).collection("day").doc(date).get().then((revanue)=>{
                    if (revanue.data()&&revanue.data().planDetail) {     
                        planCount += (revanue.data()&&revanue.data().planDetail) ? revanue.data().planDetail.planCount : 0
                        let number = 0
                            let fun = (plan) => {
                                if (number >= Object.keys(revanue.data().planDetail.planName).length) {
                                    count += 1;
                                    countFun(totalDate[count]);
                                    return;
                                }
                                if(planName[plan] && planName[plan].length !== 0) {
                                  planName[plan].push({date: date,plan: revanue.data().planDetail.planName[plan]})
                                } else {
                                    planName[plan] = [{date: date ,plan: revanue.data().planDetail.planName[plan]}]
                                }
                                number+= 1
                                fun(Object.keys(revanue.data().planDetail.planName)[number]);
                            }
                            fun(Object.keys(revanue.data().planDetail.planName)[number]);
                    }
                    else {
                        count+= 1;
                        countFun(totalDate[count]);
                    }
                   }).catch(error=>{
                      console.error(error);
                      count+= 1;
                      countFun(totalDate[count]);
                      })
              }
            }
            countFun(totalDate[count]);
        } catch(error) {
            console.error("error",error);
            reject(error);
        }
    })
}


export const getActiveUserGraph =({commit},{startingDate,endingDate})=>{
    return new Promise((resolve,reject)=>{
        try {
            let totalDate = [];
            let startDate = new Date(startingDate).getTime();
            let endDate = new Date(endingDate).getTime();
            if(startDate == endDate){
                let addDate = new Date(startDate)
                totalDate.push(addDate);
                totalDate.push(addDate);
            }else{
                while(startDate < endDate){
                    let addDate = new Date(startDate)
                    totalDate.push(addDate);
                    var newDate = moment(startDate).add(1,'days')
                    startDate = new Date(newDate).getTime()
                }
                let addDate = new Date(endDate)
                totalDate.push(addDate);
            }
            let array = []
            let learner = []
            let tutor = []
            let child = []
            let count = 0;
            let countFun = (raw) => {
                if (count >= totalDate.length-1) {
                    let obj = {
                        label : array,
                        learner : learner,
                        tutor : tutor,
                        child : child
                    }
                    commit('setActiveUSerGraph',obj);
                    resolve("Graph Records Found.")
                  return;
                }
                else {
                    let date = moment(raw).format("DD-MM")
                    let month = moment(raw).format("YYYY-MM")
                    array.push(date)
                     db.collection(dbCollections.USERCHARTDATA).doc(month).collection("day").doc(date).get().then((revanue)=>{
                        if(revanue.exists) {
                            learner.push(revanue.data().activeLearner.length)
                            tutor.push(revanue.data().activeTutor.length)
                            child.push(revanue.data().activeChild.length)
                            count+= 1;
                            countFun(totalDate[count]);
                          }
                          else {
                            learner.push(0)
                            tutor.push(0)
                            child.push(0)
                            count+= 1;
                            countFun(totalDate[count]);
                          }
                     }).catch(error=>{
                         console.error(error);
                         count+= 1;
                         countFun(totalDate[count]);
                     })
                }
            }
            countFun(totalDate[count]);
        } catch(error) {
            console.error("error",error);
            reject(error);
        }
    })
}

export const getStatsticGraph =({commit})=>{
    return new Promise((resolve,reject)=>{
        try{
            let endData = new Date(new Date().setMonth((new Date().getMonth() - 1)))
            let getData = new Date(new Date().setMonth((new Date().getMonth() - 6)))

            let startMonth =new Date(getData).getTime();
            let Endmonth = new Date(endData).getTime();
            let totalMonthGet = []
            if(startMonth == Endmonth){
                let datToAdd = moment(startMonth).format("YYYY-MM");
                totalMonthGet.push(datToAdd)
            }else{
                while(startMonth < Endmonth){
                    let datToAdd = moment(startMonth).format("YYYY-MM");
                    totalMonthGet.push(datToAdd)
                    var newDate = moment(startMonth).add(1,'month')
                    let date =  newDate.toDate();
                    startMonth = date.getTime();
                }
                let datToAddEnd = moment(Endmonth).format("YYYY-MM");
                    totalMonthGet.push(datToAddEnd)
            }
            let profitArr = []
            let revanueArr = []
            totalMonthGet.forEach((month)=>{
                db.collection(dbCollections.UPDATEGRAPHDATA).doc(month).get().then((data)=>{
                    if (data.exists) {
                        profitArr.push(data.data().profit)
                        revanueArr.push(data.data().revanue)
                    } else {
                        profitArr.push(0)
                        revanueArr.push(0)
                    }
                })
            })
            let commitss = {
                profit: profitArr,
                revanue: revanueArr
            }
                commit('setStasticGraph',commitss);
                resolve(commitss);

        }catch(error){
            console.error("error",error);
            reject(error);
        }
    })
};


export const getLearnerHistoryTransection =({commit})=>{
    return new Promise((resolve,reject)=>{
        try{
            let transactionHistory = [];
            getQuery.simpleGetDataFromRootCollectionOrderBy(dbCollections.CREDITTRANSACTIONS,(transection)=>{
                if (transection.length=== 0) {
                    commit('setLearnerTransection',transactionHistory)
                    resolve("Learner History Not Found")
                    return;
                }
                else{
                    transection.forEach(element=>{
                        var bookingDocumentId = ""
                        var count = 0
                        if (element.bookingId !== undefined) {
                            getQuery.simpleGetDataWithSpecificWhereCondition(dbCollections.BOOKING,'bookingId','==',element.bookingId,(booking)=>{
                             bookingDocumentId = booking[0] ? booking[0].id : ""
                             var obj = {
                                date : element.createdAt ?  moment(new Date( element.createdAt.seconds * 1000)).format('DD-MM-YYYY, hh:mm A'): "",
                                transactionid : element.id ? element.id : "",
                                userName : element.userDetail ? element.userDetail.firstName +" "+ element.userDetail.lastName : "",
                                bookingId : element.bookingId ? element.bookingId : null,
                                credit : element.credits ? element.credits : null,
                                debits : element.debits ? element.debits : null,
                                availCredit : element.availableCredit ? element.availableCredit : null,
                                remarks : element.remarks ? element.remarks : "",    
                                avatar : element.userDetail ? element.userDetail.profileImage : "",
                                userId : element.userId ? element.userId : "",
                                bookingDocId : (bookingDocumentId !== "") ? bookingDocumentId : "",
                                transactionType : element.transactionType
                            }
                            transactionHistory.push(obj)
                            if (transection.length == transactionHistory.length) {
                                commit('setLearnerTransection',transactionHistory)
                                resolve("Learner History  Found")
                            }
                            })
                        }
                        else{
                            var obj = {
                                date : element.createdAt ?  moment(new Date( element.createdAt.seconds * 1000)).format('DD-MM-YYYY, hh:mm A'): "",
                                transactionid : element.id ? element.id : "",
                                userName : element.userDetail ? element.userDetail.firstName +" "+ element.userDetail.lastName : "",
                                bookingId : element.bookingId ? element.bookingId : null,
                                credit : element.credits ? element.credits : null,
                                availCredit : element.availableCredit ? element.availableCredit : null,
                                remarks : element.remarks ? element.remarks : "",    
                                avatar : element.userDetail ? element.userDetail.profileImage : "",
                                userId : element.userId ? element.userId : "",
                                bookingDocId : (bookingDocumentId !== "") ? bookingDocumentId : ""
                            }
                            transactionHistory.push(obj)
                            if (transection.length == transactionHistory.length) {
                                commit('setLearnerTransection',transactionHistory)
                                resolve("Learner History  Found")
                            }
                        }
                    })
                }
                
            })
        }catch(error){
            console.error("error",error);
            reject(error);
        }
    })
};


export const getTutorHistoryTransection =({commit})=>{
    return new Promise((resolve,reject)=>{
        try{
            let transactionHistory = [];
            getQuery.simpleGetDataFromRootCollectionOrderBy(dbCollections.TUTORTRANSACTIONS,(transection)=>{
                if (transection.length=== 0) {
                    commit('setTutorTransection',transactionHistory)
                    resolve("Tutor History  Found")
                    return;
                }
                else{
                    transection.forEach(element=>{
                        var bookingDocumentId = "";
                        if (element.bookingId !== undefined) {   
                            getQuery.simpleGetDataWithSpecificWhereCondition(dbCollections.BOOKING,'bookingId','==',element.bookingId,(booking)=>{
                             bookingDocumentId = booking[0] ? booking[0].id : ""
                             var obj = {
                                date : element.createdAt ?  moment(new Date( element.createdAt.seconds * 1000)).format('DD-MM-YYYY, hh:mm A'): "",
                                transactionid : element.id ? element.id : "",
                                userName : element.userDetail ? element.userDetail.firstName +" "+ element.userDetail.lastName : "",
                                bookingId : element.bookingId ? element.bookingId : "",
                                amount : element.amount ? Number((element.amount)).toFixed(2) : 0,
                                availAmount : element.availableAmount ?  Number((element.availableAmount )).toFixed(2) : 0,
                                remarks : element.remarks ? element.remarks : "",    
                                avatar : element.userDetail ? element.userDetail.profileImage : "",
                                userId : element.userId ? element.userId : "",
                                bookingDocId : (bookingDocumentId !== "") ? bookingDocumentId : "",
                                transactionType : element.transactionType ? element.transactionType : ""
                            }
                            transactionHistory.push(obj)
                            if (transection.length == transactionHistory.length) {
                                console.log("transactionHistory",transactionHistory)
                                commit('setTutorTransection',transactionHistory)
                                resolve("Tutor History Not Found")
                            }
                            })
                        }
                        else{
                            var obj = {
                                date : element.createdAt ? moment(new Date( element.createdAt.seconds * 1000)).format('DD-MM-YYYY, hh:mm A') : "",
                                transactionid : element.id ? element.id : "",
                                userName : element.userDetail ? element.userDetail.firstName +" "+ element.userDetail.lastName : "",
                                bookingId : element.bookingId ? element.bookingId : "",
                                // credit : element.credits ? element.credits : null,
                                // availCredit : element.availableCredit ? element.availableCredit : null,
                                remarks : element.remarks ? element.remarks : "",    
                                avatar : element.userDetail ? element.userDetail.profileImage : "",
                                userId : element.userId ? element.userId : "",
                                amount : element.amount ? Number((element.amount)).toFixed(2) : 0,
                                bookingDocId : (bookingDocumentId !== "") ? bookingDocumentId : "",
                                availAmount : element.availableAmount ?  Number((element.availableAmount )).toFixed(2) : 0,
                                transactionType : element.transactionType ? element.transactionType : ""
                            }
                            transactionHistory.push(obj)
                            if (transection.length == transactionHistory.length) {
                                commit('setTutorTransection',transactionHistory)
                                resolve("Tutor History Not Found")
                            }
                        }
                    })
                   
                }
            })
        }catch(error){
            console.error("error",error);
            reject(error);
        }
    })
};


export const getAllTransection =({commit})=>{
    return new Promise((resolve,reject)=>{
        try{
            let transactionHistory = [];
            getQuery.simpleGetDataWithSpecificWhereCondition(dbCollections.AMOUNTTRANSECTION,'status','==',2,(transection)=>{
                if (transection.length===0) {
                    commit('setAllTransection',transactionHistory)
                    resolve("All Ttransection History Not Found")
                  return;
                } else {

                    let count = 0;
                    let countFun = (raw) => {
                        if (count >= transection.length) {
                            commit('setAllTransection',transactionHistory)
                            resolve("All Ttransection History Found")
                          return;
                        }
                        else {
                            if (raw.userId) {
                                getQuery.simpleGetDataWithSpecificWhereCondition(dbCollections.USERS,'id','==',raw.userId,(user)=>{
                                    if (user && Object.keys(user[0])) {
                                    let obj = {
                                        date: raw.createdAt ?  moment(new Date( raw.createdAt.seconds * 1000)).format('DD-MM-YYYY, hh:mm A'): "",
                                        transactionid: raw.transactionId ? raw.transactionId : (raw.stripeResponseObject ? raw.stripeResponseObject.charges.data[0].balance_transaction : ""),
                                        userName: user[0].firstName + " " + user[0].lastName,
                                        remarks: raw.remarks,
                                        amount:  raw.amount ? Number((raw.amount)).toFixed(2) : "",
                                        availAmount: raw.currentAmount ?  Number(raw.currentAmount).toFixed(2) : null,
                                        avatar: user[0].profilePicture,
                                        transectionType: raw.transactionType,
                                        userId: user[0].id
                                    }
                                    transactionHistory.push(obj)
                                    count+= 1;
                                    countFun(transection[count]);
                                    } else {
                                    count+= 1;
                                    countFun(transection[count]);
                                    }
                                })
                            } else {
                                count+= 1;
                                countFun(transection[count]);
                            }
                        }
                    }
                    countFun(transection[count]);
                }
              })
        }catch(error){
            console.error("error",error);
            reject(error);
        }
    })
};