import firebaseConfig from './firebaseConfig'
import firebase from "firebase"
import "firebase/firestore"
import "firebase/storage"
import "firebase/auth"
import "firebase/analytics";
import "firebase/app";
const firebaseApp = firebase.initializeApp(firebaseConfig)

firebase.getCurrentUser = () => {
    return new Promise((resolve, reject) => {
        const unsubscribe = firebase.auth().onAuthStateChanged(user => {
            unsubscribe();
            resolve(user);
        }, reject);
    })
};

firebase.firestore().settings({
    cacheSizeBytes: firebase.firestore.CACHE_SIZE_UNLIMITED
});

firebase.firestore().enablePersistence()
.catch((err) => {
    if (err.code == 'failed-precondition') {
        console.log("open one tab only")
    } else if (err.code == 'unimplemented') {
        console.log("does not support")
    }else{
        console.error(err)
    }
});

export default firebaseApp