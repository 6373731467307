import firebase from "@/utils/firebaseInit"
import { dbCollections } from "@/utils/firebaseCollection"
const db = firebase.firestore();
export const getFaqsData =({commit})=>{
    return new Promise((resolve,reject)=>{
        try{
            let faqs = []
            db.collection(dbCollections.SETTINGS).doc("setting")
                .collection(dbCollections.FAQ)
                .where('isDeleted','==',false)
                .orderBy('createdAt','desc')
                .get().then((snapshot)=>{
                    faqs = []
                    if(snapshot.empty){
                        resolve("No Data Found")
                    }else{
                        snapshot.forEach(doc=>{
                            faqs.push({id:doc.id,...doc.data()});
                        });
                        commit('setFaqArr',faqs);
                        if(faqs.length == 0){
                            resolve("No Data Found.");
                        }else{
                            resolve("Records Found.");
                        }
                    }
                },
                (error)=>{
                    console.error("error",error);
                });
        }catch(error){
            console.error("error",error);
            reject(error);
        }
    })
};

export const getContactUsData =({commit})=>{
    return new Promise((resolve,reject)=>{
        try{
            let contactUs = []
            db.collection(dbCollections.SETTINGS).doc("setting")
                .collection(dbCollections.CONTACT_US)
                .where('isDeleted','==',false)
                .orderBy('createdAt','desc')
                .get().then((snapshot)=>{
                    contactUs = []
                    if(snapshot.empty){
                        resolve("No Data Found")
                    }else{
                        snapshot.forEach(doc=>{
                            contactUs.push({id:doc.id,...doc.data()});
                        });
                        commit('setContactUsArr',contactUs);
                        if(contactUs.length == 0){
                            resolve("No Data Found.");
                        }else{
                            resolve("Records Found.");
                        }
                    }
                },
                (error)=>{
                    console.error("error",error);
                });
        }catch(error){
            console.error("error",error);
            reject(error);
        }
    })
};
export const createFaq = ({commit},faqObj)=>{
    return new Promise((resolve,reject)=>{
        try{
            const newdata = db.collection(dbCollections.SETTINGS).doc("setting")
            newdata.collection(dbCollections.FAQ).add(faqObj)
                .then((res)=>{
                    let updatedObject = {
                        id:res.id
                    }
                    newdata.collection(dbCollections.FAQ).doc(res.id).update(updatedObject)
                    let newObj = {...faqObj, id: res.id}
                    resolve("FAQ Added");
                    commit('addedNewFaqTest',newObj)
                });
        }catch(error){
            console.error("errr",error);
            reject(error);
        }
    })
};