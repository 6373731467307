import firebase from "@/utils/firebaseInit"
import { dbCollections } from "@/utils/firebaseCollection"
const db = firebase.firestore();

export default {
  namespaced: true,
  state: {
    reviewRequest:[]
  },
  getters: {
    getReviewsRequest: state => state.reviewRequest
  },
  mutations: {
    setReviewRequestArr(state,payload){
      state.reviewRequest = payload;
    },
  },
  actions: {
    getReviewRequestData(state){
      return new Promise((resolve,reject)=>{
        try{
          let reviewRequest = [];
          db.collection(dbCollections.REVIEWS).where('status','==', 'pending').onSnapshot(snapshot=>{
              reviewRequest = [];              
              if(snapshot.empty){
                  resolve("No Data Found.");
                }
                else
                {
                    snapshot.forEach(doc=>{
                        if(doc.data())
                        reviewRequest.push(doc.data());
                    });
                    state.commit("setReviewRequestArr",reviewRequest);
                    
                    if(reviewRequest.length == 0)
                    {
                        resolve("No Data Found.");
                    }
                    else
                    {
                        resolve("Records Found.");
                };
              }
            },
            (error)=>{
              console.error("error",error);
            });
        }
        catch(error){
          console.error("error",error);
          reject(error);
        };
      });
    }
  },
}
