import firebase from "@/utils/firebaseInit"
import { dbCollections } from "@/utils/firebaseCollection"
const db = firebase.firestore();

export default {
    namespaced: true,
    state: {
        tutorComplaint: []
    },
    getters: {
        getTutorComplaint: state => state.tutorComplaint
    },
    mutations: {
        setComplaintTutorArr(state, payload) {
            state.tutorComplaint = payload;
        },
    },
    actions: {
        getComplaintData(state) {
            return new Promise((resolve, reject) => {
                try {
                    let review = [];
                    db.collection(dbCollections.COMPLAINT).onSnapshot(snapshot => {
                        review = [];
                        if (snapshot.empty) {
                            resolve("No Data Found.");
                        }
                        else {
                            snapshot.forEach(doc => {
                                if (doc.data())
                                    review.push(doc.data());
                            });
                            state.commit("setComplaintTutorArr", review);

                            if (review.length == 0) {
                                resolve("No Data Found.");
                            }
                            else {
                                resolve("Records Found.");
                            };
                        }
                    },
                        (error) => {
                            console.error("error", error);
                        });
                }
                catch (error) {
                    console.error("error", error);
                    reject(error);
                };
            });
        }
    },
}
