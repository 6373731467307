export const setFaqArr = (state,Faq)=>{
    state.faq = Faq
};
export const setContactUsArr = (state,ContactUs)=>{
    state.contactus = ContactUs
};
export const addedNewFaqTest = (state, payload)=>{
    state.faq.unshift(payload)
};
export const addedNewContactUsTest = (state, payload)=>{
    state.contactus.unshift(payload)
};
export const deleteFaq = (state,payload)=>{
    state.faq.splice(payload,1)
}
export const deleteContactUs = (state,payload)=>{
    state.contactus.splice(payload,1)
}