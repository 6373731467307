import * as mutations from './settingsMutation'
import * as actions from './settingsAction'
export default{
    namespaced: true,
    state:{
        school:[],
        syllabus:[],
        tutorStatus:[],
        status:[],
        tutorTypes:[],
        languageArr:[],
        settingArrayFeild:{},
        allStatus:[],
    },
    getters: {
        getSchools: state=>state.school,
        getSyllabus: state=>state.syllabus,
        gettutorStatus: state=>state.tutorStatus,
        getStatus: state=>state.status,
        getTutorTypes: state=>state.tutorTypes,
        getTeachingLanguages: state=>state.languageArr,
        getsettingArrayFeild: state=> state.settingArrayFeild,
        getAllStatus: state=>state.allStatus,
    },
    mutations,
    actions,
}