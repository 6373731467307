import firebase from "@/utils/firebaseInit"
import { dbCollections } from "@/utils/firebaseCollection"
const db = firebase.firestore();

export default  {
    namespaced: true,
    state:{
       mailTemplate : [],
       mailTemplateData : []
    },
    getters:{
      getEmailData : state => state.mailTemplate,
      getEmailDataNew : state => state.mailTemplateData
        
    },
    mutations:{
        setEmailTemplateArr(state,payload){
            state.mailTemplate = payload;
        },
        setEmailTemplateArrayNew(state,payload){
            state.mailTemplateData = payload;
        },
    },
    actions:{
      getMailData(state){
            return new Promise((resolve,reject)=>{
                try{
                    var mailTemplate = [];
                    db
                    .collection(dbCollections.EMAILMAILTEMPLATE)
                    .orderBy('createdAt','desc')
                    .onSnapshot(snapshot=>{
                    mailTemplate = [];
                        if(snapshot.empty){
                            resolve("No Data Found.");
                        }
                        else
                        {
                            snapshot.forEach(doc=>{
                              if(doc.data().status != 2){
                                mailTemplate.push({...doc.data()});
                              }
                            });
                            state.commit("setEmailTemplateArr",mailTemplate);
            
                            if(mailTemplate.length == 0)
                            {
                            resolve("No Data Found.");
                            }
                            else
                            {
                            resolve("Records Found.");
                            };
                        }
                    }); 
                }
                catch(error){
                    reject('error',error);
                }
            })
      },
        getMailDataOnGet(state){
            return new Promise((resolve,reject)=>{
                try{
                    var mailTemplateGet = [];
                    db
                    .collection(dbCollections.EMAILMAILTEMPLATE)
                    .orderBy('createdAt','desc')
                    .onSnapshot(snapshot=>{
                    mailTemplateGet = [];
                        if(snapshot.empty){
                            resolve("No Data Found.");
                        }
                        else
                        {
                            snapshot.docChanges().forEach((change) => {
                                if (change.type === "added") {
                                    mailTemplateGet.push(change.doc.data())
                                }
                                if (change.type === "modified") {
                                    mailTemplateGet.push(change.doc.data())
                                }
                                if (change.type === "removed") {
                                    mailTemplateGet.push(change.doc.data())
                                }
                            });
                            state.commit("setEmailTemplateArrayNew",mailTemplateGet);
            
                            if(mailTemplateGet.length == 0)
                            {
                                resolve("No Data Found.");
                            }
                            else
                            {
                                resolve("Records Found.");
                            };
                        }
                    }); 
                }
                catch(error){
                    reject('error',error);
                }
            })
        }   
      
    }
}