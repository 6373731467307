import firebase from "@/utils/firebaseInit"
import { dbCollections } from "@/utils/firebaseCollection"
const db = firebase.firestore();

export default {
    namespaced: true,
    state: {
        status: []
    },
    getters: {
        getStatus: state => state.status
    },
    mutations: {
        setStatusArr(state, payload) {
            state.status = payload;
        },
    },
    actions: {
        getStatusData(state) {
            return new Promise((resolve, reject) => {
                try {
                    let statusTemp = [];
                    db.collection(dbCollections.SETTINGS).doc('status').onSnapshot(snapshot => {
                        statusTemp = null;
                        if(snapshot.data() == undefined || snapshot.data() == null) {
                            resolve("No Data Found.");
                        }
                        else {
                            statusTemp = snapshot.data();                          
                            state.commit("setStatusArr", statusTemp);

                            if (statusTemp == null || Object.keys(statusTemp).length == 0) {
                                resolve("No Data Found.");
                            }
                            else {
                                resolve("Records Found.");
                            };
                        }
                    },
                        (error) => {
                            console.error("error", error);
                        });
                }
                catch (error) {
                    console.error("error", error);
                    reject(error);
                };
            });
        }
    },
}
