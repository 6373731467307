import firebase from "@/utils/firebaseInit"
import { dbCollections } from "@/utils/firebaseCollection"
const db = firebase.firestore();

export default {
    namespaced: true,
    state: {
        cancelSession: [],
        cancelSessionRequest: []
    },
    getters: {
        getCancelSession: state => state.cancelSession,
        getCancelSessionRequest: state => state.cancelSessionRequest,
    },
    mutations: {
        setCancelSessionArray(state, payload) {
            state.cancelSession = payload;
        },
        setCancelSessionRequestArray(state, payload) {
            state.cancelSessionRequest = payload;
        },
        cancelSessionTest(state, payload){
            state.cancelSessionRequest.push(payload)
        },
        cancelSessionTests(state, payload){
            state.cancelSession.push(payload)
        },
        testMutation(state,payload){
            state.cancelSession.splice(payload,1)
        }
    },
    actions: {
        getCancelSessionData(state) {
            return new Promise((resolve, reject) => {
                try {
                    let cancelSession = [];
                    db.collection(dbCollections.BOOKING)
                    .where("status", "==", 3)
                    .where("cancelReasonStatus", '==' , 0)
                    .get().then(snapshot => {
                        cancelSession = [];
                        if (snapshot.empty) {
                            resolve("No Data Found.");
                        }
                        else {
                            snapshot.forEach(doc => {
                                if (doc.data()){
                                    cancelSession.push(doc.data());
                                }
                            });
                            state.commit("setCancelSessionArray", cancelSession);

                            if (cancelSession.length == 0) {
                                resolve("No Data Found.");
                            }
                            else {
                                resolve(snapshot);
                            };
                        }
                    },
                        (error) => {
                            console.error("error", error);
                        });
                }
                catch (error) {
                    console.error("error", error);
                    reject(error);
                };
            });
        },
        getCancelSessionRequestData(state) {
            return new Promise((resolve, reject) => {
                try {
                    let cancelSessionRequest = [];
                    db.collection(dbCollections.BOOKING)
                    .where("status", "==", 3)
                    .where("cancelReasonStatus" , 'in' , [1,2,3])
                    .get().then(snapshot => {
                        cancelSessionRequest = [];
                        if (snapshot.empty) {
                            resolve("No Data Found.");
                        }
                        else {
                            snapshot.forEach(doc => {
                                if (doc.data()){
                                    cancelSessionRequest.push(doc.data());
                                }
                            });
                            state.commit("setCancelSessionRequestArray", cancelSessionRequest);

                            if (cancelSessionRequest.length == 0) {
                                resolve("No Data Found.");
                            }
                            else {
                                resolve("Records Found.");
                            };
                        }
                    },
                        (error) => {
                            console.error("error", error);
                        });
                }
                catch (error) {
                    console.error("error", error);
                    reject(error);
                };
            });
        }
    },
}