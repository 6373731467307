<template>
  <div
    id="app"
    class="h-100"
    :class="[skinClasses]"
  >
    <component :is="layout">
      <router-view />
    </component>

    <scroll-to-top v-if="enableScrollToTop" />
  </div>
</template>

<script>
import ScrollToTop from '@core/components/scroll-to-top/ScrollToTop.vue'

import { $themeColors, $themeBreakpoints, $themeConfig } from '@themeConfig'
import { provideToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { watch } from '@vue/composition-api'
import useAppConfig from '@core/app-config/useAppConfig'

import { useWindowSize, useCssVar } from '@vueuse/core'

import store from '@/store'

const LayoutVertical = () => import('@/layouts/vertical/LayoutVertical.vue')
const LayoutHorizontal = () => import('@/layouts/horizontal/LayoutHorizontal.vue')
const LayoutFull = () => import('@/layouts/full/LayoutFull.vue')
import { errorEmail } from '@/utils/apiRequests';
const currentFileName = "src/App.vue"
import { mapActions,mapGetters } from 'vuex';
import Vue from 'vue';
export default {
  components: {

    LayoutHorizontal,
    LayoutVertical,
    LayoutFull,

    ScrollToTop,
  },
  data(){
    return{
      event:['click','mousemove','mousedown','scroll','keypress','load'],
      logoutTimer:null
    }
  },
  mounted(){
    try{
      this.event.forEach(function (eve){
        window.addEventListener(eve,this.resetTimer)
      },this)
      this.setTimers();
    }catch(error){
      console.error(error)
      errorEmail({
          projectName : process.env.VUE_APP_PROJECTNAME,
          environment : process.env.NODE_ENV,
          fileName : currentFileName,
          hookName : "mounted",
          errorMessage : error.message
        },(res)=>{
          if(res.error){
              console.error(res.error)
          }
        });
    }
  },
  created()
  {
    //USE FOR MULTI PLACES
    Vue.prototype.$DetailPage_User_IsLearner = true;
    this.$root.$on('showToastMessage',(title,variant)=>{
      this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: title,
              variant,
              icon: 'InfoIcon'
            },
        })
    });
    let usersFound = this.getUsersList.length!=0;
    if(!usersFound) //If users array empty then fetch records
    {
      this.getUsers()
      .then(()=>{
      })
      .catch(error=>{
        console.error('error',error);
      });
    }

    //for categories
    let categoriesFound = this.getCategoryList.length != 0;
    if(!categoriesFound){
      this.getCategories().then(res=>{
      }).catch(error=>{
        console.error("error",error)
      })
    }

    let revanueFound = this.getAmountTransection.length != 0;
    if(!revanueFound){
      this.getRevanueCalc().then(res=>{
      }).catch(error=>{
        console.error("error",error)
      })
    }
    //for sessions
    let sessionsFound = this.getSessionList.length != 0;
    if(!sessionsFound){
      this.getSessions().then(res=>{
      }).catch(error=>{
        console.error("error",error)
      })
    }
    //for reviews
    let reviewsFound = this.getReviewsList.length != 0;
    if(!reviewsFound){
      this.getReviews().then(res=>{
      }).catch(error=>{
        console.error("error",error)
      })
    }
    //for dashboard review request
    let reviewsRequestFound = this.getdashboardreviewList.length != 0;
    if(!reviewsRequestFound){
      this.getReviewsRequest().then(res=>{
      }).catch(error=>{
        console.error("error",error)
      })
    }
    //for schools
    let schoolsFound = this.getSchoolList.length != 0;
    if(!schoolsFound){
      this.getSchools().then(res=>{
      }).catch(error=>{
        console.error("error",error)
      })
    }
    //for syllabus
    let syllabusFound = this.getSyllabusList.length != 0;
    if(!syllabusFound){
      this.getSyllabus().then(res=>{
      }).catch(error=>{
        console.error("error",error)
      })
    }
    //FOR STATUS
    let statusFound = this.getStatusList.length != 0;
    if(!statusFound){
      this.getStatus().then(res=>{
      }).catch(error=>{
        console.error("error",error)
      })
    }
    // //FOR ALL STATUS
    let allStatusFound = this.getAllStatusList.length != 0;
    if(!allStatusFound){
      this.getAllStatus().then(res=>{
      }).catch(error=>{
        console.error("error",error)
      })
    }
    let getLangFound = this.getTeachLanguageList.length != 0;
    if(!getLangFound){
      this.getTeachingLanguages().then(res=>{
      }).catch(error=>{
        console.error("error",error)
      })
    }
    //FOR TutorComplaints
    let gettutorCompFound = this.gettutorCompaintList.length != 0;
    if(!gettutorCompFound){
      this.getTutorComplaint().then(res=>{
      }).catch(error=>{
        console.error("error",error)
      })
    }
    //FOR bookingManagment
    let getbookingCompFound = this.getBookinglist.length != 0;
    if(!getbookingCompFound){
      this.getBooking().then(res=>{
      }).catch(error=>{
        console.error("error",error)
      })
    }
      //FOR NotificationManagement
    let getnoti = this.getNotificationList.length != 0;
    if(!getnoti){
      this.getNotifications().then(res=>{
      }).catch(error=>{
        console.error("error",error)
      })
    }
    let getStatusFound = this.getStatusList.length != 0;
    if(!getStatusFound){
      this.getStatus().then(res=>{
      }).catch(error=>{
        console.error("error",error)
      })
    }

    //For Setting
    let settingMangFound = Object.keys(this.getsettingList).length != 0;
    if (!settingMangFound) {
       this.getsettingArrayFeild().then((res)=>{
       }).catch(error=>{
        console.error("error",error)
      })
    }
  },
  watch: {
      '$route' (to) {
        document.title = to.meta.pageTitle ? ('Tutorline | '+ to.meta.pageTitle) : 'Tutorline'
      }
  },
  methods: {
    ...mapActions({
        'getUsers':'userManagement/getUsersData',
        'getCategories':'categoryManagement/getCategoriesData',
        'getRevanueCalc' : 'RevanueCalculation/getRevanue',
        'getSessions':'sesionManagement/getSessionsData',
        'getReviews':'reviews/getReviewsData',
        'getSchools' : 'settings/getSchoolData',
        'getSyllabus' : 'settings/getSyllabusData',
        'getTutorTypes' : 'settings/getTutorTypes',
        'getTeachingLanguages' : 'settings/getTeachingLanguages',
        'getTutorComplaint' : 'complainBtTutorManagement/getComplaintData',
        'getBooking' : 'booking/getBookingData',
        'getNotifications': 'notificationManagement/getNotificationsData',
        'getAllStatus' : 'settings/getAllStatusData',
        'getFaqDatas': 'faqManagement/getFaqsData',
        'getsettingArrayFeild' : 'settings/getsettingArrayFeild',
        'getStatus' : 'status/getStatusData',
        'getTacs' : 'termsAndCondition/getTacData',
        'getReviewsRequest' : 'dashboardReviewRequest/getReviewRequestData'
      }),
      setTimers(){
        try{
          this.logoutTimer = setTimeout(this.warningMessage, 3600 * 1000)
        }catch(error){
          console.error(error)
          errorEmail({
          projectName : process.env.VUE_APP_PROJECTNAME,
          environment : process.env.NODE_ENV,
          fileName : currentFileName,
          hookName : "setTimers",
          errorMessage : error.message
        },(res)=>{
          if(res.error){
              console.error(res.error)
          }
        });
        }
      },
      warningMessage(){
        try{
          if(localStorage.getItem('currentUser') && localStorage.getItem('currentUser') !== undefined && JSON.parse(localStorage.getItem('currentUser')) !== null){
          this.$root.$emit('logoutUser')
          localStorage.removeItem('jwtToken')
          }
        }catch(error){
          console.error(error)
          errorEmail({
          projectName : process.env.VUE_APP_PROJECTNAME,
          environment : process.env.NODE_ENV,
          fileName : currentFileName,
          hookName : "warningMessage",
          errorMessage : error.message
        },(res)=>{
          if(res.error){
              console.error(res.error)
          }
        });
        }
      },
      resetTimer(){
        try{
          clearTimeout(this.logoutTimer)
          this.setTimers();
        }catch(error){
          console.error(error)
          errorEmail({
          projectName : process.env.VUE_APP_PROJECTNAME,
          environment : process.env.NODE_ENV,
          fileName : currentFileName,
          hookName : "resetTimer",
          errorMessage : error.message
        },(res)=>{
          if(res.error){
              console.error(res.error)
          }
        });
        }
      }
 },
  computed: {
    ...mapGetters({
        getUsersList:'userManagement/getUsers',
        getCategoryList:'categoryManagement/getCategories',
        getSessionList:'sesionManagement/getSessions',
        getReviewsList: 'reviews/getReviews',
        getSchoolList: 'settings/getSchools',
        getSyllabusList: 'settings/getSyllabus',
        getRevanueData: 'RevanueCalculation/getRevanueCalc',
        getAmountTransection: 'RevanueCalculation/getAmountTransectionDate',
        getStatusList:'status/getStatus',
        gettutorTypesList:'settings/getTutorTypes', 
        getTeachLanguageList:'settings/getTeachingLanguages',
        gettutorCompaintList:'complainBtTutorManagement/getTutorComplaint',
        getBookinglist : 'booking/getBooking', 
        getNotificationList : 'notificationManagement/getNotifications',     
        getAllStatusList:'settings/getAllStatus' ,
        getFaqDatasList: 'faqManagement/getFaq',
        getTacList : 'termsAndCondition/getTAC',
        getsettingList : 'settings/getsettingArrayFeild',
        getdashboardreviewList:'dashboardReviewRequest/getReviewsRequest'
      }), // lists from store.
    
    layout() {
      if (this.$route.meta.layout === 'full') return 'layout-full'
      return `layout-${this.contentLayoutType}`
    },
    contentLayoutType() {
      return this.$store.state.appConfig.layout.type
    },
  },
  beforeCreate() {
    // Set colors in theme
    const colors = ['primary', 'secondary', 'success', 'info', 'warning', 'danger', 'light', 'dark']

    // eslint-disable-next-line no-plusplus
    for (let i = 0, len = colors.length; i < len; i++) {
      $themeColors[colors[i]] = useCssVar(`--${colors[i]}`, document.documentElement).value.trim()
    }

    // Set Theme Breakpoints
    const breakpoints = ['xs', 'sm', 'md', 'lg', 'xl']

    // eslint-disable-next-line no-plusplus
    for (let i = 0, len = breakpoints.length; i < len; i++) {
      $themeBreakpoints[breakpoints[i]] = Number(useCssVar(`--breakpoint-${breakpoints[i]}`, document.documentElement).value.slice(0, -2))
    }

    // Set RTL
    const { isRTL } = $themeConfig.layout
    document.documentElement.setAttribute('dir', isRTL ? 'rtl' : 'ltr')
  },
  setup() {
    const { skin, skinClasses } = useAppConfig()
    const { enableScrollToTop } = $themeConfig.layout

    // If skin is dark when initialized => Add class to body
    if (skin.value === 'dark') document.body.classList.add('dark-layout')

    // Provide toast for Composition API usage
    // This for those apps/components which uses composition API
    // Demos will still use Options API for ease
    provideToast({
      hideProgressBar: true,
      closeOnClick: false,
      closeButton: false,
      icon: false,
      timeout: 3000,
      transition: 'Vue-Toastification__fade',
    })

    // Set Window Width in store
    store.commit('app/UPDATE_WINDOW_WIDTH', window.innerWidth)
    const { width: windowWidth } = useWindowSize()
    watch(windowWidth, val => {
      store.commit('app/UPDATE_WINDOW_WIDTH', val)
    })

    return {
      skinClasses,
      enableScrollToTop,
    }
  },
}
</script>