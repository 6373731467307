/**
 * 
 * This file is contains all of the firestore collection name which is used in our 
 * project. If your have required to create any new collection then you must be decalred here
 * and used as globally.
 * 
 */

/** ROOT COLLECTION NAME **/
const dbCollections = {
    APIURL:process.env.VUE_APP_FIREBASE_API_URL,//Local
    USERS : 'users',
    RESTAURANT_PRODUCTS : 'restaurantProducts',
    SETTINGS : 'settings',
    CATEGORY :  'category',
    SESSIONS : 'sessions',
    REVIEWS : 'reviews',
    BANNERMANAGEMENTSTORAGE : 'bannerManagement/',
    REFERMANAGMENT: 'refermanagment/',
    UPDATEGRAPHDATA: 'updateGraphData',
    BANNERMANAGEMENT : 'bannerManagement',
    REQUESTFORCATEGORY : 'requestForCategory',
    SCHOOL : 'school',
    EMAILMAILTEMPLATE:'emailTemplate',
    SYLLABUS : 'syllabus',
    BOOKING :'bookings',
    USERSTATUS :'userStatus',
    TUTORTYPES : 'tutorTypes',
    COMPLAINT: 'complaint',
    NOTIFICATIONMANAGEMENT:'notificationManagement',
    PLAN: 'plans',
    FAQ:'faq',
    TAC:"termsAndCondition",
    TESTTAC:"testTermsAndCondition",
    CREDITTRANSACTIONS:"creditTransactions",
    TUTORTRANSACTIONS:"tutorTransactions",
    CONNECTEDUSERS : "connectedUsers",
    PRIVACY:"privacyPolicy",
    GRADE:"grade",
    WARNING_RESET: "warningReset",
    UPDATERECORDS: "updateRecords",
    CONTACT_US:"contactUs",
    AMOUNTTRANSECTION:"amountTransactions",
    WITHDRAW_REQUEST:"withdrawRequest",
    PAYMENTGATWAYS: "paymentgatways",
    USERCHARTDATA: "userChartData",
}
const settingsCollectionDocs = {
    DOCUMENTCATEGORY: 'documentCategory',
    TEACHINGLANGUAGES:'teachingLanguages',
    STATUS: 'status',
}
module.exports = {
    dbCollections,
    settingsCollectionDocs
};