import firebase from "@/utils/firebaseInit"
import { dbCollections } from "@/utils/firebaseCollection"
const db = firebase.firestore();

export default {
  namespaced: true,
  state: {
    users:[]
  },
  getters: {
    getUsers: state => state.users
  },
  mutations: {
    setUsersArr(state,payload){
      state.users = payload;
    },
  },
  actions: {
    getUsersData(state){
      return new Promise((resolve,reject)=>{
        try{
          let users = [];
          db
            .collection(dbCollections.USERS)
            .orderBy('createdAt','desc')
            .onSnapshot(snapshot=>{
              users = [];
              let latestData = {}
              if(snapshot.empty){
                resolve("No Data Found.");
              }
              else
              {
                snapshot.forEach((doc)=>{
                  if(doc.data().userType!='admin'){                  
                    let addObj = doc.data();
                    addObj.id = doc.id; 
                    addObj.userRandomId = doc.data().userId;

                    if(users.length == 0){
                      addObj.isLatest = true;
                      latestData = addObj                      
                    }else{
                      if(addObj.userRandomId > latestData.userRandomId){
                        users[parseInt(users.length) - 1].latestData = false;
                        addObj.isLatest = true;
                        latestData = addObj  
                      }
                    }
                    users.push(addObj);
                  }
                });
                state.commit("setUsersArr",users);

                if(users.length == 0)
                {
                  resolve("No Data Found.");
                }
                else
                {
                  resolve("Records Found.");
                };
              }
              
            },
            (error)=>{
              console.error("error",error);
            });
        }
        catch(error){
          console.error("error",error);
          reject(error);
        };
      });
    }
  },
}
