export const setRevanueCalc = (state,revanueCalculationA)=>{
    state.revanueCalculation = revanueCalculationA
};
export const setAmountTransectionDate = (state,amountTransectionDateA)=>{
    state.amountTransectionDate = amountTransectionDateA
};
export const setRevanueGraph = (state,revanueGraph) =>{
    state.revanueGraph = revanueGraph
}
export const setPlanGraph = (state,planGraph) =>{
    state.planGraph = planGraph
}
export const setStasticGraph = (state,stasticGraph) =>{
    state.stasticGraph = stasticGraph
}
export const setLearnerTransection = (state,learnerTransection) =>{
    state.learnerHistory = learnerTransection
}
export const setTutorTransection = (state,TutorTransection) =>{
    state.tutorHistory = TutorTransection
}
export const setAllTransection = (state,AllTransection) =>{
    state.allTransectionHistory = AllTransection
}
export const setActiveUSerGraph = (state,activeUserGraph) =>{
    state.activeUserGraph = activeUserGraph
}